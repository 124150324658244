import { TrackProps } from 'react-player'
import { LiveVideo, PaidVideo, VideoType, VideoWithPreview } from 'store/modules/currentVideo/@types'
import { _get } from 'utils/get'

export const isAllowedToPlay = (video: LiveVideo | PaidVideo | VideoWithPreview, loggedIn: boolean, isPreviewOver: boolean) => {
  if (!video) {
    return false
  } else {
    switch (video.type) {
      case VideoType.PAID:
        return loggedIn && video.alreadyCharged
      case VideoType.WITH_PREVIEW:
        return !isPreviewOver || (loggedIn && video.alreadyCharged)
      case VideoType.LIVE:
      default:
        return true
    }
  }
}

export const getCurrentVideoSource = (
  video: LiveVideo | PaidVideo | VideoWithPreview,
  loggedIn: boolean,
  shouldPlayPreview: boolean,
  quality: string,
  shouldPlay: boolean,
) => {
  // TODO in current implementation only LIVE videos support multiple qualities
  if (!shouldPlay) {
    return undefined
  }

  switch (video.type) {
    case VideoType.PAID:
      // This should not happen, for safety purposes
      if (!loggedIn) {
        return null
      }
      return _get(video, 'sources', 0, 'URL')
    case VideoType.WITH_PREVIEW:
      if (shouldPlayPreview) {
        return _get(video, 'preview', 'streamPreviewUrl')
      }
      // should not happen, for safety purposes
      if (!loggedIn) {
        return null
      }
      return _get(video, 'sources', 0, 'URL')
    case VideoType.LIVE:
      const sources = _get(video, 'sources')
      if (!sources || !sources.length) {
        return ''
      }
      return _get(sources.find(source => _get(source, 'quality') === quality), 'URL') || _get(sources, 0, 'URL')
    default:
      return null
  }
}

export const getMediaBoxClassNames = (isFullScreen: boolean, isPlaying: boolean, isVideoOver: boolean) => {
  return `vp-media-box vp-player-media-box ${isFullScreen ? 'is-fullscreen' : ''} ${
    isPlaying ? 'playing' : isVideoOver ? 'ended' : 'paused'
  }`
}

export const getPlayerClassNames = (isSeeking: boolean, controlsVisible: boolean, isPreviewOver: boolean) => {
  return `vp-player-wrapper ${isSeeking ? 'vp-player-is-seeking' : ''} ${controlsVisible ? 'vp-player-controls-visible' : ''} ${
    isPreviewOver ? '' : 'vp-preview'
  }`
}

export const getPlayerConfig = (subtitles: TrackProps[], selectedSubtitlesLabel: string) => ({
  file: {
    tracks: (subtitles || []).map((track: TrackProps) => ({
      ...track,
      default: track.label === selectedSubtitlesLabel,
    })),
    attributes: {
      crossOrigin: 'anonymous',
    },
  },
})
