import ExitFullScreenIcon from 'components/icons/exit-fullscreen'
import FullScreenIcon from 'components/icons/fullscreen'
import { AllowedKeys } from 'components/KeyboardEventHandler'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from './styled'

interface Props {
  isFullScreen: boolean
  setFullScreen: (forceClose?: boolean) => void
}

const FullScreen = ({ isFullScreen, setFullScreen }: Props) => {
  const { t } = useTranslation()
  const [buttonRef, setButtonRef] = useState(null)
  const buttonReference = useCallback((node: Element) => {
    !buttonRef && setButtonRef(node)
  }, [])

  return (
    <Wrapper
      ref={buttonReference}
      data-tip={isFullScreen ? t('Non-Fullscreen') : t('Fullscreen')}
      className={`vp-fullscreen-btn ${isFullScreen ? 'vp-fullscreen-btn-fullscreen' : 'vp-fullscreen-btn-not-fullscreen'}`}
      data-testid="vp-fullscreen-btn"
      onClick={e => {
        setFullScreen()
        buttonRef && buttonRef.blur && buttonRef.blur()
        e.stopPropagation && e.stopPropagation()
        e.nativeEvent && e.nativeEvent.stopImmediatePropagation && e.nativeEvent.stopImmediatePropagation()
      }}
      onKeyDown={e => {
        ;[AllowedKeys.SPACE, AllowedKeys.SPACE_CHAR].some(key => [e.nativeEvent.key, e.nativeEvent.code].includes(key)) &&
          e.nativeEvent.stopImmediatePropagation()
      }}
    >
      {!isFullScreen && <FullScreenIcon />}
      {isFullScreen && <ExitFullScreenIcon />}
    </Wrapper>
  )
}

export default FullScreen
