import { useEffect, useRef } from 'react'

export const useEventListener = (eventName: string, handler: (e: Event) => void, element = document, config?: AddEventListenerOptions) => {
  const savedHandler = useRef(null)

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const isSupported = element && element.addEventListener

    if (!isSupported) return undefined

    const eventListener = event => savedHandler.current(event)

    element.addEventListener(eventName, eventListener, config)

    return () => {
      element.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element])
}

export const useInterval = (callback, delay) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current()
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
