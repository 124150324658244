import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const Replay = ({ size = 20 }: Props) => {
  return (
    <SVG width={size} height={size} viewBox="0 0 48 48">
      {/* tslint:disable-next-line */}
      <path d="M24 10V2L14 12l10 10v-8c6.63 0 12 5.37 12 12s-5.37 12-12 12-12-5.37-12-12H8c0 8.84 7.16 16 16 16s16-7.16 16-16-7.16-16-16-16z" />
    </SVG>
  )
}

export default Replay
