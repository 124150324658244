import PauseIcon from 'components/icons/pause'
import PlayArrow from 'components/icons/play'
import Replay from 'components/icons/replay'
import { AllowedKeys } from 'components/KeyboardEventHandler'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEventListener } from 'utils/hooks'
import { Wrapper } from './styled'

interface Props {
  isPlaying: boolean
  setIsPlaying: (play: boolean) => void
  isClickable?: boolean
  isFullScreen?: boolean
  isVideoOver?: boolean
}

const PlayPause = ({ isPlaying, setIsPlaying, isClickable = true, isFullScreen, isVideoOver }: Props) => {
  const { t } = useTranslation()
  const [wrapperRef, setWrapperRef] = useState()
  const wrapperRefCb = useCallback(node => {
    !wrapperRef && setWrapperRef(node)
  }, [])

  useEventListener('keydown', (e: KeyboardEvent) => {
    if (isFullScreen && wrapperRef && [e.key, e.code].includes(AllowedKeys.TAB) && document.activeElement.tagName === 'BODY') {
      e.preventDefault()
      wrapperRef.focus()
    }
  })

  const renderIcon = () => {
    if (isVideoOver) {
      return <Replay />
    } else if (isPlaying) {
      return <PauseIcon />
    } else {
      return <PlayArrow />
    }
  }

  return (
    <Wrapper
      ref={wrapperRefCb}
      data-tip={isPlaying ? t('Pause') : t('Play')}
      data-testid="vp-playpause-btn"
      className={`vp-playpause-btn ${isPlaying ? 'vp-playpause-btn-pause' : 'vp-playpause-btn-play'}`}
      onClick={() => isClickable && setIsPlaying(!isPlaying)}
      onKeyDown={e => {
        ;[AllowedKeys.SPACE, AllowedKeys.SPACE_CHAR].some(key => [e.nativeEvent.code, e.nativeEvent.key].includes(key)) &&
          e.nativeEvent.stopImmediatePropagation()
      }}
    >
      {renderIcon()}
    </Wrapper>
  )
}

export default PlayPause
