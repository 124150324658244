import { PersistedPlaybackData } from 'store/modules/playbackData/@types'

export enum Types {
  UPDATE_PLAYBACK_DATA = 'playbackData/UPDATE_PLAYBACK_DATA',
}

export const updatePlaybackData = (data: PersistedPlaybackData) => ({
  type: Types.UPDATE_PLAYBACK_DATA,
  payload: data,
})
