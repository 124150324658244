import { useEffect } from 'react'
import { TrackProps } from 'react-player'

export const useSubtitles = (subtitles?: TrackProps[], subtitlesUrl?: string, getSubtitles?: () => void) => {
  useEffect(() => {
    const hasNoSubtitlesYet = !subtitles || !subtitles.length

    if (hasNoSubtitlesYet && subtitlesUrl) {
      getSubtitles()
    }
  }, [])
}
