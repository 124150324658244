import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const VolumeMuted = ({ size = 16 }: Props) => {
  return (
    <SVG data-testid="unmute-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <path d="M14 18v12h8l10 10V8L22 18h-8z" />
    </SVG>
  )
}

export default VolumeMuted
