export const formatSecondsToHHMMSS = (seconds: number) => {
  const rounded = Math.abs(Math.floor(seconds))

  const hours = Math.floor(rounded / 60 / 60)
  const minutes = Math.floor(rounded / 60) - hours * 60
  const secondsRemaining = rounded - Math.floor(seconds / 60) * 60

  return `${hours ? `${toTwoDigits(hours)}:` : ''}${toTwoDigits(minutes)}:${toTwoDigits(secondsRemaining)}`
}

const toTwoDigits = (val: number) => {
  if (!val) {
    return '00'
  }

  const asString = String(val)

  if (asString.length > 1) {
    return asString
  }

  return `0${asString}`
}
