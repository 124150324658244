import SkipIcon from 'components/icons/skip'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInterval } from 'utils/hooks'
import { IconWrapper, SkipBtn, TextWrapper, Wrapper } from './styled'

const MIN_AD_DURATION = 5000
const INTERVAL_TIME = 300

interface Props {
  onClick: () => void
  running: boolean
}

const Skip = ({ onClick, running }: Props) => {
  const { t } = useTranslation()

  const [timeLeft, setTimeLeft] = useState(MIN_AD_DURATION)
  const [canSkipAd, setCanSkipAd] = useState(false)

  useInterval(() => {
    setTimeLeft(current => {
      if (!running) {
        return current
      }
      if (current - INTERVAL_TIME <= 0) {
        setCanSkipAd(true)
        return 0
      }
      return current - INTERVAL_TIME
    })
  }, INTERVAL_TIME)

  return (
    <Wrapper
      className="vp-skip-vast-container"
      data-testid="vp-skip-vast-container"
      onClick={e => {
        e.preventDefault()
        canSkipAd && onClick()
      }}
    >
      <SkipBtn disabled={!canSkipAd}>
        <TextWrapper className={`vp-skip-vast-text`} data-testid="vp-skip-vast-text">
          {timeLeft ? t('Skip Ad in {1}', { 1: Math.round(timeLeft / 1000) }) : t('Skip Ad')}
        </TextWrapper>
        <IconWrapper className="vp-skip-vast-icon">
          <SkipIcon size={30} />
        </IconWrapper>
      </SkipBtn>
    </Wrapper>
  )
}

export default Skip
