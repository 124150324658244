import SubtitlesIcon from 'components/icons/subtitles'
import { AllowedKeys } from 'components/KeyboardEventHandler'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TrackProps } from 'react-player'
import { connect } from 'react-redux'
import { selectCurrentVideo, selectSubtitles } from 'store/modules/currentVideo/selectors'
import { toggleSubtitlesSettings } from 'store/modules/globalPlayerData/actions'
import { selectControlsVisibility } from 'store/modules/globalPlayerData/selectors'
import { updatePlaybackData } from 'store/modules/playbackData/actions'
import { selectPlaybackData } from 'store/modules/playbackData/selectors'
import { _get } from 'utils/get'
import { Redux } from '../../../../@types'
import { Clickable, Dropdown, Option, SubtitlesButton, Wrapper } from './styled'

interface ComponentProps {
  canBeStyled?: boolean
}

interface StateProps {
  fileId: string
  selectedSubtitlesLabel: string
  subtitleTracks: TrackProps[]
  controlsVisible: boolean
}

interface DispatchProps {
  updatePlaybackData: typeof updatePlaybackData
  toggleSubtitlesSettings: typeof toggleSubtitlesSettings
}

interface Props extends ComponentProps, StateProps, DispatchProps {}

const SubtitlesSettings = ({
  selectedSubtitlesLabel,
  subtitleTracks,
  updatePlaybackData,
  fileId,
  toggleSubtitlesSettings,
  canBeStyled,
  controlsVisible,
}: Props) => {
  const { t } = useTranslation()
  const [isFocusWithin, setFocusWithin] = useState(false)

  const handleKeyDown = (e, position) => {
    if ([e.nativeEvent.code, e.nativeEvent.key].includes(AllowedKeys.ARROW_DOWN) && refs[position]) {
      e.nativeEvent.stopImmediatePropagation()
      e.preventDefault()

      if (refs[position + 1]) {
        refs[position + 1].focus()
      } else {
        mainButton && mainButton.focus()
      }
    } else if ([e.nativeEvent.code, e.nativeEvent.key].includes(AllowedKeys.ARROW_UP) && refs[position]) {
      e.nativeEvent.stopImmediatePropagation()
      e.preventDefault()

      if (refs[position - 1]) {
        refs[position - 1].focus()
      } else {
        mainButton && mainButton.focus()
      }
    }
  }

  const handleMainButtonKeyDown = e => {
    if ([e.nativeEvent.code, e.nativeEvent.key].includes(AllowedKeys.ARROW_UP) && refs[subtitleTracks.length + 1]) {
      e.nativeEvent.stopImmediatePropagation()
      e.preventDefault()
      refs[subtitleTracks.length + 1].focus()
    }
  }

  const handleSelectSubtitles = (track: TrackProps, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e.target && e.target instanceof HTMLButtonElement) {
      e.target.blur && e.target.blur()
    }
    updatePlaybackData({ fileId, subtitles: { selectedSubtitlesLabel: track.label } })
  }

  const [refs, setRefs] = useState({})
  const buttonsRefs = useCallback(
    (node: HTMLButtonElement, index: number) => {
      if (node && !refs[index]) {
        setRefs({ ...refs, [index]: node })
      }
    },
    [refs],
  )

  const [mainButton, setMainButtonRef] = useState(null)
  const mainButtonRef = useCallback((node: HTMLButtonElement) => {
    if (node && !mainButton) {
      setMainButtonRef(node)
    }
  }, [])

  if (!subtitleTracks || !subtitleTracks.length) {
    return null
  }

  return (
    <Wrapper
      controlsVisible={controlsVisible}
      isFocusWithin={isFocusWithin}
      data-testid="vp-subtitles-container"
      className="vp-subtitles-container"
    >
      <SubtitlesButton
        data-testid="vp-subtitles-btn"
        className="vp-subtitles-btn"
        data-tip={t('Subtitles')}
        ref={mainButtonRef}
        onKeyDown={handleMainButtonKeyDown}
      >
        <SubtitlesIcon />
      </SubtitlesButton>
      <Dropdown data-tip={t('Subtitles')} className="vp-subtitles-dropdown">
        {canBeStyled && (
          <Clickable
            ref={node => buttonsRefs(node, 0)}
            className="vp-subtitles-dialog-toggle"
            key="settings"
            onClick={() => toggleSubtitlesSettings()}
            onKeyDown={e => handleKeyDown(e, 0)}
          >
            {t('subtitles settings')}
          </Clickable>
        )}
        <Option
          ref={node => buttonsRefs(node, 1)}
          key="no-subtitles"
          onKeyDown={e => handleKeyDown(e, 1)}
          selected={!selectedSubtitlesLabel}
          className="vp-subtitles-disable"
          data-testid="vp-subtitles-disable"
          onFocus={() => setFocusWithin(true)}
          onBlur={() => setFocusWithin(false)}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            if (e.target && e.target instanceof HTMLButtonElement) {
              e.target.blur && e.target.blur()
            }
            updatePlaybackData({ fileId, subtitles: { selectedSubtitlesLabel: null } })
          }}
        >
          {t('subtitles off')}
        </Option>
        {(subtitleTracks || []).map((track, index) => (
          <Option
            ref={node => buttonsRefs(node, index + 2)}
            onKeyDown={e => handleKeyDown(e, index + 2)}
            key={track.label}
            selected={track.label === selectedSubtitlesLabel}
            className="vp-subtitles-option"
            data-testid="vp-subtitles-option"
            onFocus={() => setFocusWithin(true)}
            onBlur={() => setFocusWithin(false)}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSelectSubtitles(track, e)}
          >
            {track.label}
          </Option>
        ))}
      </Dropdown>
    </Wrapper>
  )
}

export default connect(
  (state: Redux) => {
    const currentVideo = selectCurrentVideo(state)
    const playbackData = selectPlaybackData(currentVideo.fileId)(state)

    return {
      fileId: currentVideo.fileId,
      selectedSubtitlesLabel: _get(playbackData, 'subtitles', 'selectedSubtitlesLabel'),
      subtitleTracks: selectSubtitles(state),
      controlsVisible: selectControlsVisibility(state),
    }
  },
  { updatePlaybackData, toggleSubtitlesSettings },
)(SubtitlesSettings)
