import VolumeOff from 'components/icons/volume-off'
import { AllowedKeys } from 'components/KeyboardEventHandler'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FloatingUnMuteButton } from './styled'

interface Props {
  unMute: () => void
}

const UnMuteVolume = ({ unMute }: Props) => {
  const { t } = useTranslation()

  return (
    <FloatingUnMuteButton
      onClick={unMute}
      onKeyDown={e => {
        ;[AllowedKeys.SPACE, AllowedKeys.SPACE_CHAR].some(key => [e.nativeEvent.code, e.nativeEvent.key].includes(key)) &&
          e.nativeEvent.stopImmediatePropagation()
      }}
      className="vp-volume-btn-floating"
      data-testid="vp-volume-btn-floating"
      data-tip={t('Unmute')}
    >
      <VolumeOff />
    </FloatingUnMuteButton>
  )
}

export default UnMuteVolume
