import Dialog from 'components/Dialog'
import { AllowedKeys } from 'components/KeyboardEventHandler'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { LiveVideo, PaidVideo, VideoType, VideoWithPreview } from 'store/modules/currentVideo/@types'
import { purchaseVideo } from 'store/modules/currentVideo/actions'
import { selectCurrentVideo } from 'store/modules/currentVideo/selectors'
import { DialogOptions } from 'store/modules/globalPlayerData/@types'
import { closeDialog, openDialog } from 'store/modules/globalPlayerData/actions'
import { selectDialogOptions, selectDialogType, selectDialogVisibility } from 'store/modules/globalPlayerData/selectors'
import { useEventListener } from 'utils/hooks'
import { Redux } from '../../@types'

const SCROLL_TRIGGERING_KEYS = [
  AllowedKeys.ARROW_UP,
  AllowedKeys.ARROW_DOWN,
  AllowedKeys.PAGE_UP,
  AllowedKeys.PAGE_DOWN,
  AllowedKeys.HOME,
  AllowedKeys.END,
]

export enum DialogType {
  LOGIN_DIALOG = 'login_dialog',
  LOW_CREDIT_DIALOG = 'low_credit_dialog',
  PURCHASE_DIALOG = 'purchase_dialog',
}

interface Props {
  visible: boolean
  type: DialogType
  openDialog: typeof openDialog
  closeDialog: typeof closeDialog
  currentVideo: LiveVideo | PaidVideo | VideoWithPreview
  purchaseVideo: typeof purchaseVideo
  options: DialogOptions
}

const ConnectedDialog = ({ visible, type, openDialog, closeDialog, currentVideo, purchaseVideo, options = {} }: Props) => {
  const { t } = useTranslation()

  const getVideoFileSize = () => {
    if (!currentVideo || currentVideo.type === VideoType.LIVE) {
      return ''
    }
    return currentVideo.fileSize
  }

  const handleLogIn = () => {
    if (currentVideo && currentVideo.type !== VideoType.LIVE) {
      currentVideo.actions.onLogIn && currentVideo.actions.onLogIn()
      closeDialog()
    }
  }

  const handleRecharge = () => {
    if (currentVideo && currentVideo.type !== VideoType.LIVE) {
      currentVideo.actions.onRechargeCredit && currentVideo.actions.onRechargeCredit()
      closeDialog()
    }
  }

  const handlePurchase = () => {
    purchaseVideo({
      onSuccess: () => {
        options.onOK && options.onOK()
        closeDialog()
      },
    })
  }

  const dialogMap = {
    [DialogType.LOGIN_DIALOG]: {
      message: t('You need to log in to play the full video'),
      onOK: () => handleLogIn(),
      onCancel: () => {
        closeDialog()
        options.onCancel && options.onCancel()
      },
      okText: t('Log in'),
    },
    [DialogType.LOW_CREDIT_DIALOG]: {
      message: t('Not enough credit to play the full video'),
      onOK: () => handleRecharge(),
      onCancel: () => {
        closeDialog()
        options.onCancel && options.onCancel()
      },
      okText: t('Recharge credit'),
    },
    [DialogType.PURCHASE_DIALOG]: {
      headline: t('Play video for {1} of credit?', { 1: getVideoFileSize() }),
      message: t(
        // tslint:disable-next-line
        `By paying for streaming of this video, you'll be granted access to download it in the next 24h as well as stream without any further payment`,
      ),
      onOK: () => handlePurchase(),
      onCancel: () => {
        closeDialog()
        options.onCancel && options.onCancel()
      },
      okText: t('Play video'),
    },
  }

  const preventScroll = (e: Event) => {
    if (visible) {
      e.preventDefault && e.preventDefault()
    }
  }

  useEventListener('DOMMouseScroll', preventScroll, document, { passive: false })
  useEventListener('wheel', preventScroll, document, { passive: false })
  useEventListener('mousewheel', preventScroll, document, { passive: false })
  useEventListener('touchmove', preventScroll, document, { passive: false })

  useEventListener('keydown', (e: KeyboardEvent) => {
    if (SCROLL_TRIGGERING_KEYS.some(key => [e.code, e.key].includes(key))) {
      preventScroll(e)
    }
  })

  const props = dialogMap[type]

  if (!props) return null

  return <Dialog visible={visible} {...props} />
}

export default connect(
  (state: Redux) => ({
    type: selectDialogType(state),
    visible: selectDialogVisibility(state),
    currentVideo: selectCurrentVideo(state),
    options: selectDialogOptions(state),
  }),
  { openDialog, closeDialog, purchaseVideo },
)(ConnectedDialog)
