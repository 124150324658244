import styled, { AnyStyledComponent } from 'styled-components'

interface HandleProps {
  percent: number
  vertical?: boolean
  animationDuration?: number
}

interface StyledRailProps {
  vertical?: boolean
  animationDuration?: number
}

interface CompletedRailProps extends StyledRailProps {
  percent: number
  animationDuration?: number
}

export const SliderWrapper = styled.div`
  display: inline;
  width: 100%;
  height: 100%;
  align-items: stretch;

  .vp-slider {
    position: relative;
    width: 100%;
    touch-action: none;
    height: 100%;
  }
`

export const ClickableRail = styled.div`
  position: absolute;
  width: ${({ vertical }: StyledRailProps) => (vertical ? '40px' : '100%')};
  height: ${({ vertical }: StyledRailProps) => (vertical ? '100%' : '30px')};
  cursor: pointer;
  touch-action: none;
`

export const StyledRail = styled.div`
  position: absolute;
  top: ${({ vertical }: StyledRailProps) => (vertical ? 'auto' : '50%')};
  left: ${({ vertical }: StyledRailProps) => (vertical ? '50%' : 'auto')};
  transform: ${({ vertical }: StyledRailProps) => (vertical ? 'translate(-50%,0)' : 'translate(0,-50%)')};
  width: ${({ vertical }: StyledRailProps) => (vertical ? '4px' : '100%')};
  height: ${({ vertical }: StyledRailProps) => (vertical ? '100%' : '4px')};
  background-color: ${({ theme }) => theme.colors.seekBarBg};
  transition: ${({ animationDuration }: StyledRailProps) => `${animationDuration / 1000}s linear width`};
`

export const CompletedRail = styled.div.attrs(({ percent, vertical }: CompletedRailProps) => ({
  style: {
    width: vertical ? '4px' : `${percent}%`,
    height: vertical ? `${percent}%` : '4px',
  },
}))`
  position: absolute;
  top: ${({ vertical }: CompletedRailProps) => (vertical ? 'auto' : '50%')};
  left: ${({ vertical }: CompletedRailProps) => (vertical ? '50%' : 'auto')};
  transform: ${({ vertical }: CompletedRailProps) => (vertical ? 'translate(-50%,0)' : 'translate(0,-50%)')};
  background-color: ${({ theme }) => theme.colors.seekBarCompleteBg};
  bottom: ${({ vertical }) => (vertical ? 0 : 'auto')};
  transition: ${({ animationDuration }: CompletedRailProps) => `${animationDuration / 1000}s linear width`};
`

// frequently changing styles in attrs for optimization
export const ClickableHandle = styled.div.attrs(({ vertical, percent }: HandleProps) => ({
  style: {
    left: vertical ? '50%' : `${percent}%`,
    top: vertical ? `${percent}%` : '50%',
  },
}))`
  position: absolute;
  z-index: 5;
  transform: ${({ vertical }: HandleProps) => (vertical ? 'translate(-50%,0)' : 'translate(0,-50%)')};
  width: ${({ vertical }: HandleProps) => (vertical ? '40px' : '20px')};
  height: ${({ vertical }: HandleProps) => (vertical ? '20px' : '40px')};
  cursor: pointer;
  background: none;
  transition: ${({ animationDuration }: HandleProps) => `${animationDuration / 1000}s linear left`};
`

// frequently changing styles in attrs for optimization
export const StyledHandle = styled.button.attrs(({ vertical, percent }: HandleProps) => ({
  style: {
    left: vertical ? '50%' : `${percent}%`,
    top: vertical ? `${percent}%` : '50%',
  },
}))`
  transform: ${({ vertical }: HandleProps) => (vertical ? 'translate(-50%,0)' : 'translate(0,-50%)')};
  margin: 0;
  border: 0;
  padding: 0;
  color: #fff;
  min-width: 0;
  position: absolute;
  z-index: 2;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.seekBarCompleteBg};
  transition: ${({ animationDuration }: HandleProps) => `${animationDuration / 1000}s linear left`};
` as AnyStyledComponent
