import { Redux } from '../../../@types'

export const selectGlobalData = (state: Redux) => state.global

export const selectLoggedIn = (state: Redux) => selectGlobalData(state).loggedIn
export const selectPlayerLanguage = (state: Redux) => selectGlobalData(state).language
export const selectGlobalError = (state: Redux) => selectGlobalData(state).error

const selectUi = (state: Redux) => selectGlobalData(state).ui
export const selectDialogVisibility = (state: Redux) => selectUi(state).dialogOpen
export const selectDialogType = (state: Redux) => selectUi(state).dialogType
export const selectDialogOptions = (state: Redux) => selectUi(state).dialogOptions
export const selectSubtitlesSettingsOpen = (state: Redux) => selectUi(state).subtitlesSettingsOpen
export const selectControlsVisibility = (state: Redux) => selectUi(state).controlsVisible
export const selectControlsVisibilityCountdown = (state: Redux) => selectUi(state).controlsVisibilityCountdown

export const selectMonitoring = (state: Redux) => selectGlobalData(state).monitoring
