export enum Color {
  WHITE = 'White',
  BLACK = 'Black',
  RED = 'Red',
  BLUE = 'Blue',
  GREEN = 'Green',
  YELLOW = 'Yellow',
  PURPLE = 'Magenta',
  AZURE = 'Cyan',
}

export enum Transparency {
  VISIBLE = '1',
  HALF_VISIBLE = '0.5',
  INVISIBLE = '0',
}

export enum ShadowStyle {
  NONE = 'None',
  RAISED = 'Raised',
  INSET = 'Depressed',
  BALANCED = 'Uniform',
  REGULAR = 'Dropshadow',
}

export enum FontFamily {
  SANS_SERIF = 'Proportional Sans-Serif',
  MONOSPACE_SANS_SERIF = 'Monospace Sans-Serif',
  SERIF = 'Proportional Serif',
  MONOSPACE_SERIF = 'Monospace Serif',
  PLAYFUL = 'Casual',
  HANDWRITTEN = 'Script',
  SMALL_CAPS = 'Small Caps',
}

export type FontSize = 0.5 | 0.75 | 1 | 1.25 | 1.5 | 1.75 | 2 | 3 | 4

export interface SubtitlesStylesReducer {
  fontColor: Color
  fontTransparency: Transparency
  background: Color
  backgroundTransparency: Transparency
  fontSize: FontSize
  shadowStyle: ShadowStyle
  fontFamily: FontFamily
}
