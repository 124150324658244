import ConnectedDialog from 'components/ConnectedDialog'
import ErrorDialog from 'components/ErrorDialog'
import Loader from 'components/Loader'
import PlayCTA from 'components/PlayCTA'
import Player from 'components/Player'
import { PlayerWrapper as InnerWrapper } from 'components/Player/styled'
import PreviewLinkExpirationWatcher from 'components/PreviewLinkExpirationWatcher'
import Vast from 'components/Vast'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { PaidVideo, VideoType, VideoWithPreview } from 'store/modules/currentVideo/@types'
import { getPreviewLink, setAutoplay, tryPurchaseVideo } from 'store/modules/currentVideo/actions'
import { selectCurrentVideo } from 'store/modules/currentVideo/selectors'
import { selectLoggedIn } from 'store/modules/globalPlayerData/selectors'
import { Redux } from '../../@types'
import { PlayerWrapper, Root, SlideshowWrapper } from './styled'

interface Props {
  getPreviewLink?: typeof getPreviewLink
  tryPurchaseVideo?: typeof tryPurchaseVideo
  currentVideo?: ReturnType<typeof selectCurrentVideo>
  setAutoplay?: typeof setAutoplay
}

const MainElement = ({ currentVideo, getPreviewLink, tryPurchaseVideo, setAutoplay }: Props) => {
  const [shouldVideoBeSeen, setVideoVisibility] = useState(false)
  const [shouldShowBeSeen, setShowVisibility] = useState(null)
  const [shouldPlayAd, setShouldPlayAd] = useState(null)
  const [slideshow, setSlideshow] = useState(null)
  const [hasBeenUnmuted, setHasBeenUnmuted] = useState(false)

  // @NOTE: Autoplay live video, but show Ads
  useEffect(() => {
    if (currentVideo && !shouldVideoBeSeen && !shouldShowBeSeen) {
      const timeout = setTimeout(() => {
        if (currentVideo && currentVideo.type === VideoType.LIVE) {
          setVideoVisibility(true)
          setShowVisibility(false)
        }
      }, 1000)

      return () => clearTimeout(timeout)
    }

    return undefined
  }, [currentVideo])

  useEffect(() => {
    if (!currentVideo) return
    // @NOTE: only play ADs for live videos and previews
    setShouldPlayAd(!!currentVideo.ads)
    setShowVisibility(!currentVideo.autoplay && !!currentVideo.slideshowElement)
  }, [currentVideo && currentVideo.autoplay])

  useEffect(() => {
    if (currentVideo && currentVideo.slideshowElement && !slideshow) {
      const copy = currentVideo.slideshowElement.cloneNode(true)
      setSlideshow(copy)

      currentVideo.slideshowElement.style.display = 'none'
    }
  }, [shouldShowBeSeen, currentVideo && currentVideo.slideshowElement])

  const handleLiveVideoClick = () => {
    setShowVisibility(false)
    setVideoVisibility(true)
  }

  const handlePaidVideoClick = (video: PaidVideo) => {
    tryPurchaseVideo({
      onSuccess: () => {
        setShowVisibility(false)
        setAutoplay(true)
        setVideoVisibility(true)
      },
    })
  }

  const handleVideoWithPreviewClick = (video: VideoWithPreview) => {
    if (currentVideo.alreadyCharged) {
      tryPurchaseVideo({
        onSuccess: () => {
          setShowVisibility(false)
          setAutoplay(true)
          setVideoVisibility(true)
        },
        onCancel: () => {
          setShowVisibility(true)
        },
      })
    } else {
      getPreviewLink({
        onSuccess: () => {
          setShowVisibility(false)
          setAutoplay(true)
          setVideoVisibility(true)
        },
      })
    }
  }

  const handleSlideshowClick = () => {
    if (currentVideo) {
      switch (currentVideo.type) {
        case VideoType.WITH_PREVIEW:
          handleVideoWithPreviewClick(currentVideo)
          break
        case VideoType.PAID:
          setVideoVisibility(true)
          handlePaidVideoClick(currentVideo)
          break
        case VideoType.LIVE:
        default:
          handleLiveVideoClick()
      }
    }
  }

  const handleSlideshowAndAdsOver = () => {
    setShouldPlayAd(false)
    setVideoVisibility(true)
  }

  const handleKickBack = () => {
    setShowVisibility(true)
    setVideoVisibility(false)
  }

  const handleTouchStart = () => {
    document.activeElement && (document.activeElement as any).blur && (document.activeElement as any).blur()
  }

  if (!currentVideo) {
    return null
  }

  if (currentVideo.slideshowElement && !slideshow) {
    return (
      <PlayerWrapper onTouchStart={handleTouchStart}>
        <InnerWrapper>
          <Loader />
        </InnerWrapper>
        <ConnectedDialog />
      </PlayerWrapper>
    )
  }

  if (shouldPlayAd !== false) {
    return (
      <Root>
        <PlayerWrapper className="vp-root" onTouchStart={handleTouchStart}>
          <Player shouldPlay={shouldVideoBeSeen && !shouldPlayAd} onKickBack={handleKickBack} hasBeenUnmuted={hasBeenUnmuted} />
          <Vast
            shouldPlayAd={shouldPlayAd}
            shouldShowBeSeen={shouldShowBeSeen}
            handleSlideshowClick={handleSlideshowClick}
            onAdsEnd={handleSlideshowAndAdsOver}
            onUnmute={() => setHasBeenUnmuted(true)}
            slideshow={
              slideshow && (
                <SlideshowWrapper className="vp-slideshow-wrapper">
                  <div dangerouslySetInnerHTML={{ __html: slideshow.outerHTML }} />
                  <ConnectedDialog />
                </SlideshowWrapper>
              )
            }
          />
          <ConnectedDialog />
          <ErrorDialog />
          <PreviewLinkExpirationWatcher />
        </PlayerWrapper>
      </Root>
    )
  }

  return (
    <Root>
      <PlayerWrapper className="vp-root" onTouchStart={handleTouchStart}>
        <Player shouldPlay={shouldVideoBeSeen} onKickBack={handleKickBack} hasBeenUnmuted={hasBeenUnmuted} />
        {shouldShowBeSeen && slideshow && (
          <SlideshowWrapper className="vp-slideshow-wrapper">
            <PlayCTA onClick={handleSlideshowClick} showText />
            <div dangerouslySetInnerHTML={{ __html: slideshow.outerHTML }} />
            <ConnectedDialog />
          </SlideshowWrapper>
        )}
        <ConnectedDialog />
        <ErrorDialog />
        <PreviewLinkExpirationWatcher />
      </PlayerWrapper>
    </Root>
  )
}

export default connect(
  (state: Redux) => ({
    currentVideo: selectCurrentVideo(state),
    loggedIn: selectLoggedIn(state),
  }),
  { getPreviewLink, tryPurchaseVideo, setAutoplay },
)(MainElement)
