import { Actions, Ads, Charge, Monitoring, Preview, VideoPlayerConfig, VideoSource } from '@types'
import { LiveVideo, PaidVideo, VideoType, VideoWithPreview } from 'store/modules/currentVideo/@types'
import { _get } from 'utils/get'

export const parseGlobalData = (config: VideoPlayerConfig, monitoringConfig?: Monitoring) => ({
  language: config.locale.language,
  loggedIn: config.loggedIn,
  monitoring: monitoringConfig,
})

export const parsePlaybackData = (config: VideoPlayerConfig) => {
  const firstSubtitlesLabel = _get(config, 'subtitles', 0, 'label')

  return {
    fileId: config.fileId,
    ...(firstSubtitlesLabel
      ? {
          subtitles: {
            selectedSubtitlesLabel: firstSubtitlesLabel,
          },
        }
      : {}),
  }
}

export const parseCurrentVideoData = (
  config: VideoPlayerConfig,
  charge: Charge,
  preview: Preview,
  actions: Actions,
  ads: Ads,
): LiveVideo | PaidVideo | VideoWithPreview => {
  const videoType = config.videoType

  switch (videoType) {
    case VideoType.WITH_PREVIEW:
      return {
        fileId: config.fileId,
        hasCredit: config.hasCredit,
        slideshowElement: config.slideshow,
        alreadyCharged: config.alreadyCharged,
        autoplay: config.autoplay,
        sources: config.sources.map((source: VideoSource, index: number) => ({
          ...source,
          quality: _get(source, 'quality') || `${index + 1}`,
        })),
        type: videoType,
        fileSize: _get(charge, 'fileSize'),
        getCheckURL: _get(charge, 'getCheckURL'),
        watchRefreshURL: _get(charge, 'watchRefreshURL'),
        watchExpire: _get(charge, 'watchExpire'),
        watchURL: _get(charge, 'watchURL'),
        subtitlesUrl: config.subtitlesURL,
        actions,
        preview,
        ads,
      }
    case VideoType.PAID:
      return {
        fileId: config.fileId,
        hasCredit: config.hasCredit,
        slideshowElement: config.slideshow,
        alreadyCharged: config.alreadyCharged,
        autoplay: config.autoplay,
        sources: config.sources.map((source: VideoSource, index: number) => ({
          ...source,
          quality: _get(source, 'quality') || `${index + 1}`,
        })),
        type: videoType,
        fileSize: _get(charge, 'fileSize'),
        getCheckURL: _get(charge, 'getCheckURL'),
        watchRefreshURL: _get(charge, 'watchRefreshURL'),
        watchExpire: _get(charge, 'watchExpire'),
        watchURL: _get(charge, 'watchURL'),
        subtitlesUrl: config.subtitlesURL,
        actions,
      }
    case VideoType.LIVE:
    default:
      return {
        fileId: config.fileId,
        hasCredit: config.hasCredit,
        slideshowElement: config.slideshow,
        subtitles: config.subtitles,
        alreadyCharged: config.alreadyCharged,
        autoplay: config.autoplay,
        sources: config.sources.map((source: VideoSource, index: number) => ({
          ...source,
          quality: _get(source, 'quality') || `${index + 1}`,
        })),
        type: videoType,
        subtitlesUrl: config.subtitlesURL,
        ads,
      }
  }
}
