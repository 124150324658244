import { useFullScreenListener } from 'components/Player/hooks/useFullscreenListener'
import { useState } from 'react'
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

export const useFullScreen = (internalPlayer: HTMLVideoElement, wrapper: Element) => {
  const [isFullScreen, setFullScreen] = useState(false)

  const handleFullScreen = () => {
    if (typeof screenfull !== 'boolean') {
      screenfull.isFullscreen !== isFullScreen && setFullScreen(screenfull.isFullscreen)
    } else if (internalPlayer) {
      // @NOTE browser specific
      if (internalPlayer && internalPlayer.webkitDisplayingFullscreen === false) {
        setFullScreen(internalPlayer.webkitDisplayingFullscreen)
      }
    }
  }

  const handleSetFullScreen = (forceClose?: boolean) => {
    if (typeof screenfull !== 'boolean') {
      if (isFullScreen) {
        screenfull.exit()
      } else if (!forceClose) {
        screenfull.request(findDOMNode(wrapper) as Element)
      }
    } else if (internalPlayer && internalPlayer.webkitExitFullScreen && internalPlayer.webkitEnterFullScreen) {
      // handle iOS
      if (isFullScreen || forceClose) {
        internalPlayer.webkitExitFullScreen()
      } else {
        internalPlayer.webkitEnterFullScreen()
      }
    }
  }

  // listen to fullscreen requests
  useFullScreenListener(() => handleFullScreen())

  return { isFullScreen, handleSetFullScreen, handleFullScreen }
}
