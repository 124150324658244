import styled from 'styled-components'

export const Link = styled.a`
  display: block;
  position: absolute;
  z-index: 10;
  color: #fff;
  font-size: 11px;
  background: ${({ theme }) => theme.colors.floatingControlsBg};
  padding: 10px;
  border: 1px solid #fff;
  cursor: pointer;
  transition: 1s all ease-in-out, 0.25s background linear;
  text-decoration: none;

  top: 10px;
  right: 10px;

  &:hover {
    background: ${({ theme }) => theme.colors.floatingControlsHoverBg};
    color: #fff;
  }
`
