import { TrackProps } from 'react-player'
import { SubtitlesResponse } from 'store/modules/currentVideo/@types'

export const parseSubtitlesResponse = (response: SubtitlesResponse['subtitles']): TrackProps[] => {
  return Object.entries(response).map(([key, value]) => ({
    kind: 'subtitles',
    src: value,
    srcLang: key,
    label: key,
  }))
}
