import { connect } from 'react-redux'
import { setExpiresIn } from 'store/modules/currentVideo/actions'
import { selectPreview } from 'store/modules/currentVideo/selectors'
import { _get } from 'utils/get'
import { useInterval } from 'utils/hooks'
import { Redux } from '../../@types'

interface Props {
  expiresIn: number
  setExpiresIn: typeof setExpiresIn
}

export const DEFAULT_EXPIRATION_TIME = 120
const INTERVAL_TIME = 1000

const PreviewLinkExpirationWatcher = ({ expiresIn, setExpiresIn }: Props) => {
  useInterval(() => {
    if (expiresIn > 0) setExpiresIn(expiresIn - 1)
  }, INTERVAL_TIME)

  return null
}

export default connect(
  (state: Redux) => {
    const expiresIn = _get(selectPreview(state), 'expiresIn')

    return {
      expiresIn: typeof expiresIn === 'number' ? expiresIn : DEFAULT_EXPIRATION_TIME,
    }
  },
  { setExpiresIn },
)(PreviewLinkExpirationWatcher)
