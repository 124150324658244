import AirPlay from 'components/Controls/_components/AirPlay'
import VideoQualitySwitch from 'components/Controls/_components/QualitySwitch'
import SubtitlesSettings from 'components/Controls/_components/SubtitlesSettings'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setControlsVisibility, setControlsVisibilityCountdown } from 'store/modules/globalPlayerData/actions'
import { selectControlsVisibility, selectControlsVisibilityCountdown } from 'store/modules/globalPlayerData/selectors'
import { useEventListener } from 'utils/hooks'
import { Redux, VideoSource } from '../../@types'
import FullScreen from './_components/FullScreen'
import PlayPause from './_components/PlayPause'
import Progress from './_components/Progress'
import SeekBar from './_components/SeekBar'
import Skip from './_components/Skip'
import Volume from './_components/Volume'
import { Cell, FullWidthCell, Row, Table, Wrapper } from './styled'

const INTERVAL_TIME = 1000

interface Props {
  isPlaying: boolean
  setIsPlaying: (play: boolean) => void
  canSkip: boolean
  onSkip?: () => void
  isFullScreen: boolean
  onSetFullScreen: (forceClose?: boolean) => void
  isMuted: boolean
  mute: (mute: boolean) => void
  volume: number
  setVolume: (volume: number) => void
  videoLength: number
  seekPosition: number
  onSeekTo?: (position: number) => void
  mutedByDefault?: boolean
  selectedQuality?: string
  sources?: VideoSource[]
  selectQuality?: (quality: string) => void
  isAd?: boolean
  onSeekStart?: () => void
  isSeeking?: boolean
  hideSubtitlesMenu?: boolean
  hasSubtitlesSettings?: boolean
  visible?: boolean
  setControlsVisibility?: typeof setControlsVisibility
  setControlsVisibilityCountdown?: typeof setControlsVisibilityCountdown
  timeLeftVisible?: number
  progressStep: number
  showInitialSeekBarHint?: boolean
  isVideoOver?: boolean
  canGoFullScreen?: boolean
  airPlayAvailable?: boolean
  onAirPlayClick?: () => void
}

const Controls = ({
  isPlaying,
  setIsPlaying,
  canSkip,
  onSkip,
  isFullScreen,
  onSetFullScreen,
  isMuted,
  mute,
  volume,
  setVolume,
  videoLength,
  seekPosition,
  onSeekTo,
  selectedQuality,
  selectQuality,
  sources,
  mutedByDefault,
  isAd,
  hasSubtitlesSettings,
  onSeekStart,
  isSeeking,
  hideSubtitlesMenu,
  visible,
  timeLeftVisible,
  setControlsVisibility,
  setControlsVisibilityCountdown,
  progressStep,
  showInitialSeekBarHint,
  isVideoOver,
  canGoFullScreen = true,
  airPlayAvailable,
  onAirPlayClick,
}: Props) => {
  const resetCountdown = (e: Event, omitTargetCheck?: boolean) => {
    if (omitTargetCheck) {
      setControlsVisibility(true)
    } else {
      const containers = document.getElementsByClassName('vp-media-box')

      let isInAnyVideo = false
      if (containers) {
        for (let i = 0; i < containers.length; i++) {
          isInAnyVideo = (e.target instanceof Node && containers[i].contains(e.target)) || isInAnyVideo
        }
      }

      if (containers && isInAnyVideo) {
        setControlsVisibility(true)
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeftVisible - INTERVAL_TIME <= 0) {
        clearInterval(interval)
        setControlsVisibilityCountdown(0)
      } else {
        setControlsVisibilityCountdown(timeLeftVisible - INTERVAL_TIME)
      }
    }, INTERVAL_TIME)

    return () => clearInterval(interval)
  }, [timeLeftVisible])

  useEffect(() => {
    if (timeLeftVisible <= 0) {
      setControlsVisibility(false)
    }
  }, [timeLeftVisible])

  useEventListener('keydown', e => {
    if ((e.target as HTMLElement).tagName === 'BODY') {
      resetCountdown(e, true)
    }
  })
  useEventListener('click', resetCountdown)
  useEventListener('mousemove', resetCountdown)
  useEventListener('scroll', e => resetCountdown(e, true))

  const isShowing = visible || !isPlaying

  return (
    <Wrapper
      visible={isShowing}
      className={`vp-controls ${isFullScreen ? 'vp-controls-fullscreen' : ''} ${isShowing ? 'vp-dialog-overlay-visible' : ''}`}
    >
      <Row>
        <Cell className="vp-controls-left">
          <Table>
            <Row>
              <Cell>
                <PlayPause isPlaying={isPlaying} setIsPlaying={setIsPlaying} isFullScreen={isFullScreen} isVideoOver={isVideoOver} />
              </Cell>
              {canSkip && (
                <Cell>
                  <Skip onSkip={onSkip} />
                </Cell>
              )}
              <Cell>
                <Volume
                  isMuted={isMuted}
                  mute={mute}
                  volume={volume}
                  setVolume={setVolume}
                  mutedByDefault={mutedByDefault}
                  onSpacePress={() => setIsPlaying(!isPlaying)}
                />
              </Cell>
            </Row>
          </Table>
        </Cell>
        <FullWidthCell>
          <SeekBar
            seekPosition={seekPosition}
            onSeekTo={onSeekTo}
            isClickable={!isAd}
            videoLength={videoLength}
            onSeekStart={onSeekStart}
            isSeeking={isSeeking}
            animationDuration={progressStep}
            onSpacePress={() => setIsPlaying(!isPlaying)}
            showInitialHint={showInitialSeekBarHint}
          />
        </FullWidthCell>
        <Cell className="vp-controls-right">
          <Table>
            <Row>
              <Cell>
                <Progress videoLength={videoLength} seekPosition={seekPosition} step={progressStep} />
              </Cell>
              {!!selectedQuality && !!selectQuality && sources && sources.length > 1 && (
                <Cell>
                  <VideoQualitySwitch selectedQuality={selectedQuality} selectQuality={selectQuality} sources={sources} />
                </Cell>
              )}
              {!hideSubtitlesMenu && (
                <Cell>
                  <SubtitlesSettings canBeStyled={hasSubtitlesSettings} />
                </Cell>
              )}
              {airPlayAvailable && onAirPlayClick && (
                <Cell>
                  <AirPlay onClick={onAirPlayClick} />
                </Cell>
              )}
              {canGoFullScreen && (
                <Cell>
                  <FullScreen isFullScreen={isFullScreen} setFullScreen={onSetFullScreen} />
                </Cell>
              )}
            </Row>
          </Table>
        </Cell>
      </Row>
    </Wrapper>
  )
}

export default connect(
  (state: Redux) => ({
    visible: selectControlsVisibility(state),
    timeLeftVisible: selectControlsVisibilityCountdown(state),
  }),
  { setControlsVisibility, setControlsVisibilityCountdown },
)(Controls)
