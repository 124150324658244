import createReducer from 'utils/createReducer'
import { _get } from 'utils/get'
import { GlobalPlayerReducer } from './@types'
import * as actions from './actions'

const HIDE_CONTROLS_AFTER_TIME = 2000

export const initialState: GlobalPlayerReducer = {
  loggedIn: false,
  language: 'cs',
  error: null,
  ui: {
    dialogOpen: false,
    dialogType: null,
    dialogOptions: null,
    subtitlesSettingsOpen: false,
    controlsVisible: true,
    controlsVisibilityCountdown: HIDE_CONTROLS_AFTER_TIME,
  },
  monitoring: null,
}

export default createReducer(initialState, {
  [actions.Types.UPDATE_GLOBAL_DATA]: (
    state: GlobalPlayerReducer,
    action: ReturnType<typeof actions.updateGlobalData>,
  ): GlobalPlayerReducer => {
    if (!action.payload || !Object.entries(action.payload).length) {
      return state
    }

    return {
      ...state,
      ...action.payload,
    }
  },
  [actions.Types.SET_GLOBAL_ERROR]: (
    state: GlobalPlayerReducer,
    action: ReturnType<typeof actions.setGlobalError>,
  ): GlobalPlayerReducer => ({
    ...state,
    error: action.payload.error,
  }),
  [actions.Types.OPEN_DIALOG]: (state: GlobalPlayerReducer, action: ReturnType<typeof actions.openDialog>): GlobalPlayerReducer => ({
    ...state,
    ui: {
      ...state.ui,
      dialogOpen: true,
      dialogType: action.payload.dialogType,
      dialogOptions: action.payload.options,
    },
  }),
  [actions.Types.CLOSE_DIALOG]: (state: GlobalPlayerReducer): GlobalPlayerReducer => ({
    ...state,
    ui: {
      ...state.ui,
      dialogOpen: false,
      dialogType: null,
      dialogOptions: null,
    },
  }),
  [actions.Types.TOGGLE_SUBTITLES_SETTINGS]: (state: GlobalPlayerReducer): GlobalPlayerReducer => ({
    ...state,
    ui: {
      ...state.ui,
      subtitlesSettingsOpen: !_get(state, 'ui', 'subtitlesSettingsOpen'),
    },
  }),
  [actions.Types.UNSET_GLOBAL_ERROR]: (state: GlobalPlayerReducer): GlobalPlayerReducer => ({
    ...state,
    error: null,
  }),
  [actions.Types.SET_CONTROLS_VISIBILITY]: (
    state: GlobalPlayerReducer,
    action: ReturnType<typeof actions.setControlsVisibility>,
  ): GlobalPlayerReducer => ({
    ...state,
    ui: {
      ...state.ui,
      controlsVisible: action.payload.isVisible,
      controlsVisibilityCountdown: action.payload.isVisible ? HIDE_CONTROLS_AFTER_TIME : 0,
    },
  }),
  [actions.Types.SET_CONTROLS_VISIBILITY_COUNTDOWN]: (
    state: GlobalPlayerReducer,
    action: ReturnType<typeof actions.setControlsVisibilityCountdown>,
  ): GlobalPlayerReducer => ({
    ...state,
    ui: {
      ...state.ui,
      controlsVisibilityCountdown: action.payload.timeLeft,
    },
  }),
})
