import RefreshIcon from 'components/icons/refresh'
import moment from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { setRemainingPreviewTime } from 'store/modules/currentVideo/actions'
import { selectPreview, selectRemainingPreviewTime } from 'store/modules/currentVideo/selectors'
import { _get } from 'utils/get'
import { useInterval } from 'utils/hooks'
import { Redux } from '../../@types'
import { IconWrapper, Wrapper } from './styled'

const DEFAULT_PREVIEW_DURATION_SECONDS = 60

interface Props {
  initialized: boolean
  onCountdownEnd: () => void
  onClick: () => void
  isPreviewOver: boolean
  setRemainingPreviewTime?: typeof setRemainingPreviewTime
  remainingPreviewTime?: number
  previewLinkExpiresIn?: number
  countdownStopped?: boolean
}

const Countdown = ({
  setRemainingPreviewTime,
  remainingPreviewTime,
  initialized,
  onCountdownEnd,
  onClick,
  isPreviewOver,
  previewLinkExpiresIn,
  countdownStopped,
}: Props) => {
  const { t } = useTranslation()

  const formattedOutput = moment()
    .startOf('day')
    .seconds(remainingPreviewTime)
    .format('mm:ss')
  const canPreview = !countdownStopped && remainingPreviewTime > 0
  const content = canPreview ? t('Preview will end in {1}', { 1: formattedOutput }) : t('Preview ended')

  useInterval(() => {
    if (!isPreviewOver) {
      if (!remainingPreviewTime || !previewLinkExpiresIn) return

      if (remainingPreviewTime <= 1 || previewLinkExpiresIn <= 1) {
        onCountdownEnd()
        setRemainingPreviewTime(0)
      } else if (initialized || previewLinkExpiresIn <= remainingPreviewTime) {
        setRemainingPreviewTime(remainingPreviewTime - 1)
      }
    }
  }, 1000)

  return (
    <Wrapper
      className={`vp-countdown ${canPreview ? '' : 'vp-countdown-clickable'}`}
      data-testid="vp-countdown"
      onClick={!canPreview ? onClick : undefined}
      onKeyDown={e => {
        e.nativeEvent.stopImmediatePropagation()
      }}
      clickable={!canPreview}
    >
      {!canPreview && (
        <IconWrapper data-testid="vp-countdown-icon" className={`vp-countdown-icon ${canPreview ? '' : 'vp-countdown-icon-clickable'}`}>
          <RefreshIcon />
        </IconWrapper>
      )}
      {content}
    </Wrapper>
  )
}

export default connect(
  (state: Redux) => {
    const remainingTime = selectRemainingPreviewTime(state)
    const expiresIn = _get(selectPreview(state), 'expiresIn')

    return {
      remainingPreviewTime: typeof remainingTime === 'number' ? remainingTime : DEFAULT_PREVIEW_DURATION_SECONDS,
      previewLinkExpiresIn: expiresIn || 0,
    }
  },
  { setRemainingPreviewTime },
)(Countdown)
