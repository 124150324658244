import styled, { DefaultTheme, ThemeProps } from 'styled-components'

interface WrapperProps extends ThemeProps<DefaultTheme> {
  clickable: boolean
}

export const Wrapper = styled.button`
  margin: 0;
  border: 0;
  min-width: 0;
  position: absolute;
  z-index: 10;
  background: ${({ theme }: WrapperProps) => theme.colors.floatingControlsBg};
  color: #fff;
  border-radius: 2px;
  padding: 8px;
  transition: 0.25s background linear;
  top: 10px;
  left: 10px;
  fill: currentColor;
  cursor: ${({ clickable }: WrapperProps) => (clickable ? 'pointer' : 'default')};
  font-size: 11px;

  &:hover {
    background: ${({ theme, clickable }: WrapperProps) =>
      clickable ? theme.colors.floatingControlsHoverBg : theme.colors.floatingControlsBg};
  }
`

export const IconWrapper = styled.div`
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
`
