import { SubtitlesStylesReducer } from 'store/modules/subtitlesStyles/@types'

export enum Types {
  UPDATE_SUBTITLES_STYLE = 'subtitles/UPDATE_SUBTITLES_STYLE',
  RESET_SUBTITLES_STYLE = 'subtitles/RESET_SUBTITLES_STYLE',
}

export const updateSubtitlesStyle = (data: Partial<SubtitlesStylesReducer>) => ({
  type: Types.UPDATE_SUBTITLES_STYLE,
  payload: data,
})

export const resetSubtitlesStyle = () => ({
  type: Types.RESET_SUBTITLES_STYLE,
})
