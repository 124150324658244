import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { selectMonitoring } from 'store/modules/globalPlayerData/selectors'
import request from 'utils/request'
import { Monitoring } from '../../../@types'
import * as actions from './actions'

export function* watchHitPreviewStartUrl() {
  yield takeLatest(actions.Types.HIT_PREVIEW_START_URL, function* handle() {
    try {
      const monitoringData: Monitoring = yield select(selectMonitoring)

      if (monitoringData && monitoringData.previewStartUrl) {
        yield call(request, `${monitoringData.previewStartUrl}&nocache=${Date.now()}`, {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
            credentials: 'same-origin',
          },
        })

        yield put(actions.hitPreviewStartUrlSuccess())
      }
    } catch (e) {
      yield put(actions.hitPreviewStartUrlFailure(e))
    }
  })
}

export default function* globalDataSaga() {
  yield fork(watchHitPreviewStartUrl)
}
