import { useEventListener } from 'utils/hooks'

export enum AllowedKeys {
  SPACE = 'Space',
  SPACE_CHAR = ' ',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ESC = 'Escape',
  MEDIA_FAST_FORWARD = 'MediaFastForward',
  MEDIA_PAUSE = 'MediaPause',
  PAUSE = 'Pause',
  MEDIA_FAST_BACKWARD = 'MediaRewind',
  MEDIA_PLAY = 'MediaPlay',
  TAB = 'Tab',
  PAGE_UP = 'PageUp',
  PAGE_DOWN = 'PageDown',
  HOME = 'Home',
  END = 'End',
}

interface KeyDownEventHandler {
  keyCode: AllowedKeys
  callback: () => void
}

interface Props {
  handlers: KeyDownEventHandler[]
  onlyOnBodyElement?: boolean
}

const KeyboardControls = ({ handlers, onlyOnBodyElement = true }: Props) => {
  const handleKeyDown = (event: KeyboardEvent) => {
    const handler = handlers.find(handler => [event.key, event.code].includes(handler.keyCode))
    if (handler) {
      if (onlyOnBodyElement && ['BODY', 'VIDEO'].includes((event.target as HTMLElement).tagName)) {
        event.preventDefault()
        handler.callback()
      } else if (!onlyOnBodyElement) {
        handler.callback()
      }
    }
  }
  useEventListener('keydown', handleKeyDown)

  return null
}

export default KeyboardControls
