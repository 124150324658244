import React from 'react'

interface Props {
  size?: number
}

const Close = ({ size = 22 }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 686 800">
      <path
        fill="currentColor"
        // tslint:disable-next-line
        d="M512.946 500.893c0-7.589-3.125-14.732-8.482-20.089L423.66 400l80.804-80.804c5.357-5.357 8.482-12.5 8.482-20.089s-3.125-15.179-8.482-20.536l-40.179-40.179c-5.357-5.357-12.946-8.482-20.536-8.482s-14.732 3.125-20.089 8.482l-80.804 80.804-80.804-80.804c-5.357-5.357-12.5-8.482-20.089-8.482s-15.179 3.125-20.536 8.482l-40.179 40.179c-5.357 5.357-8.482 12.946-8.482 20.536s3.125 14.732 8.482 20.089L262.052 400l-80.804 80.804c-5.357 5.357-8.482 12.5-8.482 20.089s3.125 15.179 8.482 20.536l40.179 40.179c5.357 5.357 12.946 8.482 20.536 8.482s14.732-3.125 20.089-8.482l80.804-80.804 80.804 80.804c5.357 5.357 12.5 8.482 20.089 8.482s15.179-3.125 20.536-8.482l40.179-40.179c5.357-5.357 8.482-12.946 8.482-20.536zM685.714 400c0 189.286-153.571 342.857-342.857 342.857S0 589.286 0 400 153.571 57.143 342.857 57.143 685.714 210.714 685.714 400z"
      />
    </svg>
  )
}

export default Close
