import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import rootReducers from 'store/reducers'
import rootSagas from 'store/sagas'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['playback', 'subtitlesStyles'],
}

const persistedReducer = persistReducer(persistConfig, rootReducers)
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware<any>(...middlewares)))

rootSagas.map((saga: any) => sagaMiddleware.run(saga, store.dispatch))

export const persistor = persistStore(store)
export default store
