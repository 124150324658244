import styled, { AnyStyledComponent } from 'styled-components'

interface WrapperProps {
  tooltipPosition: number
}

export const Wrapper = styled.div.attrs(({ tooltipPosition }: WrapperProps) => ({
  style: {
    left: tooltipPosition,
  },
}))`
  position: absolute;
  cursor: pointer;
  height: 0;
  pointer-events: none;
` as AnyStyledComponent

export const Text = styled.div`
  position: relative;
  font-size: 11px;
  top: -50px;
  background: ${({ theme }) => theme.colors.timeIndicatorBg};
  padding: 5px;
  pointer-events: none;
`

export const Caret = styled.div`
  position: relative;
  top: -41px;
  width: 0;
  border-right: 2px solid ${({ theme }) => theme.colors.timeIndicatorBg};
  height: 6px;
  float: left;
`
