import styled from 'styled-components'

export const Wrapper = styled.div`
  font-size: 11px;
  white-space: nowrap;
  vertical-align: middle;
  margin: 0 10px;
  height: 100%;
`

export const Slash = styled.span`
  margin: 0 3px;
  vertical-align: middle;
  position: relative;
  top: 50%;
  transform: translate(0, -60%);
  display: inline-block;
`

export const TimeSpan = styled.span`
  vertical-align: middle;
  position: relative;
  top: 50%;
  transform: translate(0, -60%);
  display: inline-block;
`
