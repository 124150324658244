import { DialogType } from 'components/ConnectedDialog'
import { DialogOptions } from 'store/modules/globalPlayerData/@types'

export enum Types {
  UPDATE_GLOBAL_DATA = 'globalPlayerData/UPDATE_GLOBAL_DATA',
  SET_GLOBAL_ERROR = 'globalPlayerData/SET_GLOBAL_ERROR',
  UNSET_GLOBAL_ERROR = 'globalPlayerData/UNSET_GLOBAL_ERROR',
  OPEN_DIALOG = 'globalPlayerData/OPEN_DIALOG',
  CLOSE_DIALOG = 'globalPlayerData/CLOSE_DIALOG',
  TOGGLE_SUBTITLES_SETTINGS = 'globalPlayerData/TOGGLE_SUBTITLES_SETTINGS',
  SET_CONTROLS_VISIBILITY = 'globalPlayerData/SET_CONTROLS_VISIBILITY',
  SET_CONTROLS_VISIBILITY_COUNTDOWN = 'globalPlayerData/SET_CONTROLS_VISIBILITY_COUNTDOWN',
  HIT_PREVIEW_START_URL = 'monitoring/HIT_PREVIEW_START_URL',
  HIT_PREVIEW_START_URL_SUCCESS = 'monitoring/HIT_PREVIEW_START_URL_SUCCESS',
  HIT_PREVIEW_START_URL_FAILURE = 'monitoring/HIT_PREVIEW_START_URL_FAILURE',
}

export const updateGlobalData = (data: { loggedIn: boolean; language: string }) => ({
  type: Types.UPDATE_GLOBAL_DATA,
  payload: data,
})

export const setGlobalError = (error: Error) => ({
  type: Types.SET_GLOBAL_ERROR,
  payload: { error: error },
})

export const unsetGlobalError = () => ({
  type: Types.UNSET_GLOBAL_ERROR,
})

export const openDialog = (dialogType: DialogType, options?: DialogOptions) => ({
  type: Types.OPEN_DIALOG,
  payload: { dialogType, options },
})

export const closeDialog = () => ({
  type: Types.CLOSE_DIALOG,
})

export const toggleSubtitlesSettings = () => ({
  type: Types.TOGGLE_SUBTITLES_SETTINGS,
})

export const setControlsVisibility = (isVisible: boolean) => ({
  type: Types.SET_CONTROLS_VISIBILITY,
  payload: { isVisible },
})

export const setControlsVisibilityCountdown = (timeLeft: number) => ({
  type: Types.SET_CONTROLS_VISIBILITY_COUNTDOWN,
  payload: { timeLeft },
})

export const hitPreviewStartUrl = () => ({
  type: Types.HIT_PREVIEW_START_URL,
})

export const hitPreviewStartUrlSuccess = () => ({
  type: Types.HIT_PREVIEW_START_URL_SUCCESS,
})

export const hitPreviewStartUrlFailure = (error: Error) => ({
  type: Types.HIT_PREVIEW_START_URL_FAILURE,
})
