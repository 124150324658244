import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const PauseIcon = ({ size = 16 }: Props) => {
  return (
    <SVG data-testid="pause-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <path d="M12 38h8V10h-8v28zm16-28v28h8V10h-8z" />
    </SVG>
  )
}

export default PauseIcon
