import { VideoType } from 'store/modules/currentVideo/@types'
import { _get } from 'utils/get'
import { Redux } from '../../../@types'

const subState = (state: Redux) => state.currentVideo

export const selectCurrentVideo = (state: Redux) => subState(state).data
export const selectCurrentVideoLoading = (state: Redux) => subState(state).isLoading
export const selectCurrentVideoError = (state: Redux) => subState(state).error

export const selectRemainingPreviewTime = (state: Redux) => {
  const currentVideo = selectCurrentVideo(state)

  if (!currentVideo || currentVideo.type === VideoType.LIVE || currentVideo.type === VideoType.PAID) {
    return null
  }

  return currentVideo.remainingPreviewTime
}
export const selectVastUrl = (state: Redux) => _get(subState(state), 'data', 'ads', 'vastUrl')

export const selectPreview = (state: Redux) => {
  const currentVideo = selectCurrentVideo(state)

  if (!currentVideo || currentVideo.type === VideoType.LIVE || currentVideo.type === VideoType.PAID) {
    return null
  }

  return currentVideo.preview
}

export const selectSubtitles = (state: Redux) => selectCurrentVideo(state).subtitles
