import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const RefreshIcon = ({ size = 16 }: Props) => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      {/* tslint:disable-next-line */}
      <path d="M35.3 12.7C32.41 9.8 28.42 8 24 8 15.16 8 8.02 15.16 8.02 24S15.16 40 24 40c7.45 0 13.69-5.1 15.46-12H35.3c-1.65 4.66-6.07 8-11.3 8-6.63 0-12-5.37-12-12s5.37-12 12-12c3.31 0 6.28 1.38 8.45 3.55L26 22h14V8l-4.7 4.7z" />
    </SVG>
  )
}

export default RefreshIcon
