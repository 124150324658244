import * as yup from 'yup'

export const configValidation = yup.object().shape({
  videoPlayerConfig: yup
    .object()
    .shape({
      videoType: yup
        .string()
        .oneOf(['withPreview', 'withoutPreview', 'live'])
        .required(),
      loggedIn: yup.bool(),
      hasCredit: yup.bool(),
      alreadyCharged: yup.bool(),
      autoplay: yup.bool(),
      fileId: yup.string().required(),
      language: yup.string(),
      locale: yup.object().shape({
        language: yup.string().required(),
        loadPath: yup.string().required(),
        addPath: yup.string(),
      }),
      videoElement: yup.mixed().required(),
      slideshow: yup.mixed().required(),
      subtitlesURL: yup.string(),
      sources: yup.array().of(
        yup.object().shape({
          quality: yup.string(),
          URL: yup.string().required(),
        }),
      ),
      subtitles: yup.array().of(
        yup.object().shape({
          kind: yup.string().required(),
          src: yup.string().required(),
          srcLang: yup.string().required(),
          label: yup.string().required(),
        }),
      ),
    })
    .required(),
  charge: yup
    .object()
    .shape({
      fileSize: yup.string(),
      getCheckURL: yup.string(),
      watchRefreshURL: yup.string(),
      watchExpire: yup.number(),
      watchURL: yup.string(),
    })
    .notRequired(),
  preview: yup
    .object()
    .shape({
      fileSize: yup.string().required(),
      getPreviewURL: yup.string().required(),
    })
    .notRequired(),
  actions: yup
    .object()
    .shape({
      onLogIn: yup.mixed(),
      onUserNotLoggedIn: yup.mixed(),
      onLowCredit: yup.mixed(),
      onRechargeCredit: yup.mixed(),
    })
    .notRequired(),
  ads: yup
    .object()
    .shape({
      vastUrl: yup.string().required(),
    })
    .notRequired(),
  monitoring: yup
    .object()
    .shape({
      previewStartUrl: yup.string(),
    })
    .notRequired(),
})
