import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from './styled'

interface Props {
  url: string
}

const AdLink = ({ url }: Props) => {
  const { t } = useTranslation()

  return (
    <Link className="vp-advertiser-link" href={url} target="_blank">
      {t('Advertiser pages')}
    </Link>
  )
}

export default AdLink
