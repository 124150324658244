import styled from 'styled-components'
import { isChromeBelowV61 } from 'utils/browserChecks'

export const PlayerWrapper = styled.div`
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;

  & .vp-player-wrapper {
    display: flex;
  }

  .vp-tooltip {
    padding: 8px 10px;
    border: 1px solid #fff;
    background-color: ${({ theme }) => theme.colors.tooltipBg};
    color: #fff;
    font-size: 11px;

    &.type-dark.place-top:after {
      border-top-color: #fff;
    }
  }

  ${() => {
    if (isChromeBelowV61()) {
      return `
          video::-internal-media-controls-overlay-cast-button {
            display: none;
          }
        `
    }
    return ''
  }}
`

export const SlideshowWrapper = styled.div`
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
`
