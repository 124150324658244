import { GetPreviewSuccessResponse, LiveVideo, PaidVideo, SubtitlesResponse, VideoWithPreview } from 'store/modules/currentVideo/@types'

export enum Types {
  UPDATE_CURRENT_VIDEO = 'currentVideo/UPDATE_CURRENT_VIDEO',
  SET_REMAINING_PREVIEW_TIME = 'currentVideo/SET_REMAINING_PREVIEW_TIME',
  REQUEST_PURCHASE_VIDEO = 'currentVideo/REQUEST_PURCHASE_VIDEO',
  PURCHASE_VIDEO = 'currentVideo/PURCHASE_VIDEO',
  PURCHASE_VIDEO_SUCCESS = 'currentVideo/PURCHASE_VIDEO_SUCCESS',
  PURCHASE_VIDEO_ERROR = 'currentVideo/PURCHASE_VIDEO_ERROR',
  CHECK_AVAILABILITY = 'currentVideo/CHECK_AVAILABILITY',
  SET_PREVIEW_EXPIRES_IN = 'currentVideo/SET_PREVIEW_EXPIRES_IN',
  GET_PREVIEW_LINK = 'currentVideo/GET_PREVIEW_LINK',
  GET_PREVIEW_LINK_SUCCESS = 'currentVideo/GET_PREVIEW_LINK_SUCCESS',
  GET_PREVIEW_LINK_ERROR = 'currentVideo/GET_PREVIEW_LINK_ERROR',
  CANCEL_PREVIEW_FETCH = 'currentVideo/CANCEL_PREVIEW_FETCH',
  CHECK_PERMISSIONS = 'currentVideo/CHECK_PERMISSIONS',
  CHECK_PERMISSIONS_SUCCESS = 'currentVideo/CHECK_PERMISSIONS_SUCCESS',
  CHECK_PERMISSIONS_ERROR = 'currentVideo/CHECK_PERMISSIONS_ERROR',
  TRY_PURCHASE_VIDEO = 'currentVideo/TRY_PURCHASE_VIDEO',
  GET_SUBTITLES = 'currentVideo/GET_SUBTITLES',
  GET_SUBTITLES_SUCCESS = 'currentVideo/GET_SUBTITLES_SUCCESS',
  GET_SUBTITLES_ERROR = 'currentVideo/GET_SUBTITLES_ERROR',
  SET_AUTOPLAY = 'currentVideo/SET_AUTOPLAY',
}

export const updateCurrentVideo = (data: LiveVideo | PaidVideo | VideoWithPreview) => ({
  type: Types.UPDATE_CURRENT_VIDEO,
  payload: data,
})

export const setRemainingPreviewTime = (remainingTimeSeconds: number) => ({
  type: Types.SET_REMAINING_PREVIEW_TIME,
  payload: { remainingTimeSeconds },
})

export const requestPurchaseVideo = (options: { onSuccess?: () => void; omitConfirmDialog?: boolean; onCancel?: () => void }) => ({
  type: Types.REQUEST_PURCHASE_VIDEO,
  payload: options,
})

export const purchaseVideo = (options: { onSuccess?: () => void }) => ({
  type: Types.PURCHASE_VIDEO,
  payload: options,
})

export const purchaseVideoSuccess = (watchURL?: string) => ({
  type: Types.PURCHASE_VIDEO_SUCCESS,
  payload: { watchURL },
})

export const purchaseVideoError = (error: Error) => ({
  type: Types.PURCHASE_VIDEO_ERROR,
  payload: { error },
})

export const checkAvailability = (options?: { onSuccess?: () => void }) => ({
  type: Types.CHECK_AVAILABILITY,
  payload: options,
})

export const setExpiresIn = (expiresIn: number) => ({
  type: Types.SET_PREVIEW_EXPIRES_IN,
  payload: { expiresIn },
})

export const getPreviewLink = ({ onSuccess }: { onSuccess: () => void }) => ({
  type: Types.GET_PREVIEW_LINK,
  payload: { onSuccess },
})

export const getPreviewLinkSuccess = (previewData: GetPreviewSuccessResponse) => ({
  type: Types.GET_PREVIEW_LINK_SUCCESS,
  payload: previewData,
})

export const getPreviewLinkError = (error: Error) => ({
  type: Types.GET_PREVIEW_LINK_ERROR,
  error: { error },
})

export const cancelPreviewFetch = () => ({
  type: Types.CANCEL_PREVIEW_FETCH,
})

export const checkPermissions = (options?: { onSuccess?: () => void; onCancel?: () => void; onUserNotLoggedIn?: () => void }) => ({
  type: Types.CHECK_PERMISSIONS,
  payload: options,
})

export const checkPermissionsSuccess = () => ({
  type: Types.CHECK_PERMISSIONS_SUCCESS,
})

export const checkPermissionsError = (error: Error) => ({
  type: Types.CHECK_PERMISSIONS_ERROR,
  payload: { error },
})

export const tryPurchaseVideo = (options?: {
  onSuccess?: () => void
  omitConfirmDialog?: boolean
  onCancel?: () => void
  onUserNotLoggedIn?: () => void
}) => ({
  type: Types.TRY_PURCHASE_VIDEO,
  payload: options,
})

export const getSubtitles = () => ({
  type: Types.GET_SUBTITLES,
})

export const getSubtitlesSuccess = (subtitles: SubtitlesResponse['subtitles']) => ({
  type: Types.GET_SUBTITLES_SUCCESS,
  payload: { subtitles },
})

export const getSubtitlesError = (error: Error) => ({
  type: Types.GET_SUBTITLES_ERROR,
  payload: { error },
})

export const setAutoplay = (autoplay: boolean) => ({
  type: Types.SET_AUTOPLAY,
  payload: { autoplay },
})
