// FIXME use original vast-player after PR is submitted https://github.com/minznerjosh/vast-player/issues/18
import VastPlayer from '@jakubMenda/vast-player'
import { Redux } from '@types'
import Controls from 'components/Controls'
import KeyboardControls from 'components/KeyboardControls'
import Loader from 'components/Loader'
import PlayCTA from 'components/PlayCTA'
import { useFullScreen } from 'components/Player/hooks/useFullScreen'
import { getMediaBoxClassNames } from 'components/Player/utils'
import AdLink from 'components/Vast/_components/AdLink'
import { resolveClickThroughUrl } from 'components/Vast/utils'
import React, { ReactComponentElement, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { VideoType } from 'store/modules/currentVideo/@types'
import { selectCurrentVideo, selectVastUrl } from 'store/modules/currentVideo/selectors'
import { setControlsVisibility } from 'store/modules/globalPlayerData/actions'
import { selectControlsVisibility } from 'store/modules/globalPlayerData/selectors'
import { isIOS } from 'utils/browserChecks'
import { _get } from 'utils/get'
import { useEventListener, useInterval } from 'utils/hooks'
import Skip from './_components/Skip'
import UnMuteVolume from './_components/Volume'
import { VastContainer, VastWrapper, Wrapper } from './styled'

const PROGRESS_STEP = 250

interface Props {
  vastUrl?: ReturnType<typeof selectVastUrl>
  onAdsEnd: () => void
  handleSlideshowClick: () => void
  shouldShowBeSeen: boolean
  shouldPlayAd: boolean
  videoType: ReturnType<typeof selectCurrentVideo>['type']
  slideshow?: Element | ReactComponentElement<any>
  controlsVisible?: boolean
  setControlsVisibility?: (visible: boolean) => void
  onUnmute: () => void
}

const Vast = ({
  vastUrl,
  onAdsEnd,
  handleSlideshowClick,
  videoType,
  shouldPlayAd,
  shouldShowBeSeen,
  slideshow,
  controlsVisible,
  setControlsVisibility,
  onUnmute,
}: Props) => {
  const [shouldShowAdSkip, setAdSkipVisibility] = useState(false)
  const [shouldShowUnmuteIcon, setUnmuteIconVisibility] = useState(false)
  const [adPlayer, setAdPlayer] = useState(null)
  const [shouldShowPlay, setPlayIconVisibility] = useState(true)
  const [running, setRunning] = useState(false)
  const [adContainer, setAdContainer] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [duration, setDuration] = useState(0)
  const [timeRemaining, setTimeRemaining] = useState(0)
  const [wrapperRef, setWrapperRef] = useState(null)
  const [touchStartTop, setTouchStartTop] = useState()

  const wrapperRefCb = useCallback((node: Element) => {
    !wrapperRef && setWrapperRef(node)
  }, [])

  // Controls
  const [isWatchingForChanges, setIsWatchingForChanges] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [volume, setVolume] = useState(1)
  const [isMuted, setIsMuted] = useState(false)

  const handlePlay = (play: boolean) => {
    if (play) {
      if (adPlayer) {
        adPlayer.startAd().then(() => {
          setIsPlaying(true)
          setPlayIconVisibility(false)
          setRunning(true)
        })

        adPlayer.resumeAd().then(() => {
          setIsPlaying(true)
          setPlayIconVisibility(false)
          setRunning(true)
        })
      }
    } else {
      adPlayer &&
        adPlayer.pauseAd().then(() => {
          setIsPlaying(false)
          setPlayIconVisibility(true)
          setRunning(false)
        })
    }
  }

  const hasInternalPlayer = adPlayer && adPlayer.container.childNodes && adPlayer.container.childNodes.length
  const internalPlayer = hasInternalPlayer ? adPlayer.container.childNodes[0] : undefined

  const { isFullScreen, handleSetFullScreen } = useFullScreen(internalPlayer, wrapperRef)

  const reflectVolumeSettingsToState = (volume: number) => {
    setVolume(volume)
    if (volume <= 0) {
      setIsMuted(true)
    } else {
      setUnmuteIconVisibility(false)
      setIsMuted(false)
    }
  }

  const handleSetVolume = (volume: number) => {
    if (adPlayer && adPlayer.ready) {
      if (volume && onUnmute) {
        onUnmute()
      }
      adPlayer.adVolume = volume

      if (isIOS()) {
        if (!volume) {
          adPlayer.container.childNodes[0].muted = true
        } else {
          adPlayer.container.childNodes[0].muted = false
        }
        reflectVolumeSettingsToState(volume)
      }
    }
  }

  useInterval(() => {
    if (adPlayer && adPlayer.ready && isWatchingForChanges) {
      setDuration(adPlayer.adDuration)
      setTimeRemaining(adPlayer.adRemainingTime)
    }
  }, PROGRESS_STEP)

  const adContainerRef = useCallback((node: Element) => {
    !adContainer && setAdContainer(node)
  }, [])

  useEffect(() => {
    if (adContainer) {
      const player = new VastPlayer(adContainer)
      setAdPlayer(player)
    }
  }, [adContainer])

  useInterval(() => {
    // @NOTE: Check for remaining time and close AD when it's done
    if (adPlayer && adPlayer.ready && adPlayer.adRemainingTime <= 0) {
      onAllAdsEnd()
    }
  }, 500)

  useEffect(() => {
    if (adPlayer && adPlayer.container.childNodes && adPlayer.container.childNodes.length) {
      // VAST library hack - modify video tag properties here
      adPlayer.container.childNodes[0].setAttribute('playsinline', true)
      adPlayer.container.childNodes[0].removeAttribute('controls')
    }
  }, [adPlayer && adPlayer.container.childNodes && adPlayer.container.childNodes.length])

  useEffect(() => {
    if (adPlayer && adPlayer.ready && (!shouldShowBeSeen || !slideshow) && !isPlaying) {
      if (shouldPlayAd && videoType === VideoType.LIVE) {
        // @NOTE: If live video, start immediately
        adPlayer.adVolume = 0
        if (isIOS()) {
          adPlayer.container.childNodes[0].muted = true
        }

        setUnmuteIconVisibility(true)
        adPlayer.startAd().catch(e => {
          setPlayIconVisibility(true)
          adPlayer.adVolume = 1
          setUnmuteIconVisibility(false)
          onUnmute && onUnmute()
        })
      }
      if (shouldPlayAd && videoType === VideoType.WITH_PREVIEW) {
        // @NOTE: Triggered after click on preview! Start immediately
        adPlayer.adVolume = 1
        if (isIOS()) {
          adPlayer.container.childNodes[0].muted = false
        }

        setUnmuteIconVisibility(false)
        onUnmute && onUnmute()
        adPlayer.startAd().catch(e => {
          // try turning off volume
          adPlayer.adVolume = 0
          if (isIOS()) {
            adPlayer.container.childNodes[0].muted = true
          }

          setUnmuteIconVisibility(true)

          // retry playing
          adPlayer.startAd().catch(e => {
            // user interaction policy > dont autoplay
            setPlayIconVisibility(true)
            adPlayer.adVolume = 1
            if (isIOS()) {
              adPlayer.container.childNodes[0].muted = false
            }

            setUnmuteIconVisibility(false)
            onUnmute && onUnmute()
          })
        })
      }
    }
  }, [adPlayer, _get(adPlayer, 'ready'), shouldShowBeSeen, slideshow])

  useEffect(() => {
    // @NOTE: Load ad when player and URL is ready
    if (vastUrl && adPlayer && !adPlayer.ready) {
      setShowLoader(true)
      adPlayer
        .load(vastUrl)
        .catch(onAllAdsEnd)
        .then(() => {
          setShowLoader(false)
          adPlayer.once('AdStarted', () => {
            setAdSkipVisibility(true)
            setRunning(true)
            setPlayIconVisibility(false)
            setIsPlaying(true)
            setIsWatchingForChanges(true)
          })

          // emitted on resume event
          adPlayer.once('AdPlaying', () => {
            setAdSkipVisibility(true)
            setRunning(true)
            setPlayIconVisibility(false)
            setIsPlaying(true)
          })

          adPlayer.on('AdVolumeChange', () => {
            if (!isIOS()) {
              reflectVolumeSettingsToState(adPlayer.adVolume)
            }
          })

          adPlayer.once('AdPaused', () => {
            setRunning(false)
            setPlayIconVisibility(true)
            setIsPlaying(false)
          })
        })
    }
  }, [vastUrl, adPlayer])

  useEffect(() => {
    if (!shouldShowBeSeen) {
      handleSlideshowClick()
    }
  }, [shouldShowBeSeen])

  const onAllAdsEnd = (e?: Error) => {
    // @NOTE: global handled for ADs on end
    onAdsEnd()
  }

  const onUnMute = () => {
    adPlayer.adVolume = 1
    setUnmuteIconVisibility(false)
    onUnmute && onUnmute()

    if (isIOS()) {
      adPlayer.container.childNodes[0].muted = false
    }
  }

  const handlePlayClick = () => {
    handlePlay(!isPlaying)
    handleSlideshowClick()
  }

  const handleClick = () => {
    setRunning(isRunning => !isRunning)
    setPlayIconVisibility(visible => !visible)
    setIsPlaying(isPlaying => {
      if (!isPlaying) {
        adPlayer.startAd()
        adPlayer.resumeAd()
      } else {
        adPlayer.pauseAd()
      }
      return !isPlaying
    })
  }

  const handleTouchStart = e => {
    if ((e.target as HTMLElement).tagName === 'VIDEO') {
      const clientRect = e.target.getBoundingClientRect()
      const { top } = clientRect

      setTouchStartTop(top)
    }
  }

  const handleTouchEnd = e => {
    if ((e.target as HTMLElement).tagName === 'VIDEO') {
      const clientRect = e.target.getBoundingClientRect()
      const { top } = clientRect

      if (typeof touchStartTop === 'number' && Math.abs(top - touchStartTop) < 3) {
        e.preventDefault()
        controlsVisible ? e.target.click() : setControlsVisibility(true)
      }
    }

    setTouchStartTop(null)
  }

  // handleTouchStart wouldn't work properly passed as a prop in chrome
  // chrome handles events as passive by default - https://github.com/facebook/react/issues/8968
  useEventListener('touchstart', handleTouchStart, wrapperRef, { passive: false })
  useEventListener('touchend', handleTouchEnd, wrapperRef, { passive: false })

  const renderControls = () => {
    if (adPlayer && adPlayer.ready) {
      return (
        <>
          <Controls
            isPlaying={isPlaying}
            setIsPlaying={handlePlay}
            canSkip={false}
            isFullScreen={isFullScreen}
            onSetFullScreen={handleSetFullScreen}
            isMuted={isMuted}
            mute={(mute: boolean) => handleSetVolume(mute ? 0 : 1)}
            volume={volume}
            setVolume={handleSetVolume}
            videoLength={duration}
            seekPosition={(duration - timeRemaining) / duration}
            hideSubtitlesMenu
            isAd
            progressStep={PROGRESS_STEP}
            canGoFullScreen={false}
          />
          <KeyboardControls isPlaying={isPlaying} setIsPlaying={handlePlay} volume={volume} onSetVolume={handleSetVolume} />
        </>
      )
    }
    return null
  }

  if ([VideoType.WITH_PREVIEW, VideoType.LIVE].includes(videoType)) {
    if ((shouldPlayAd === false || vastUrl === undefined) && !shouldShowBeSeen) {
      onAllAdsEnd()
    }

    return (
      <VastWrapper className={getMediaBoxClassNames(isFullScreen, isPlaying, false)}>
        <Wrapper ref={wrapperRefCb} className="vp-wrapper">
          {shouldShowUnmuteIcon && <UnMuteVolume unMute={onUnMute} />}
          {shouldShowAdSkip && <Skip running={running} onClick={onAllAdsEnd} />}
          {showLoader && <Loader />}
          {shouldPlayAd !== false && <VastContainer onClick={handleClick} data-testid="vp-vast" ref={adContainerRef} id="adContainer" />}
          {shouldPlayAd !== false && !shouldShowBeSeen && renderControls()}
          {shouldShowBeSeen && slideshow}
          {shouldShowPlay && !showLoader && !isPlaying && <PlayCTA showText={true} onClick={handlePlayClick} />}
          {!isPlaying && shouldShowAdSkip && !!resolveClickThroughUrl(adPlayer) && <AdLink url={resolveClickThroughUrl(adPlayer)} />}
        </Wrapper>
      </VastWrapper>
    )
  }

  if (videoType === VideoType.PAID) {
    if (shouldPlayAd === false || vastUrl === undefined) {
      onAllAdsEnd()
    }

    return null
  }

  return null
}

export default connect(
  (state: Redux) => {
    const current = selectCurrentVideo(state)
    return {
      vastUrl: selectVastUrl(state),
      videoType: _get(current, 'type'),
      controlsVisible: selectControlsVisibility(state),
    }
  },
  { setControlsVisibility },
)(Vast)
