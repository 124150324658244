import createReducer from 'utils/createReducer'
import { _get } from 'utils/get'
import { PersistedPlaybackData, PlaybackDataReducer } from './@types'
import * as actions from './actions'

const MAX_PERSISTED_VIDEOS = 100

export const initialState: PlaybackDataReducer = {
  data: {},
}

const removeFromPlaybackData = (data: { [key: string]: PersistedPlaybackData }, fileId: string) => {
  const { [fileId]: toOmit, ...rest } = data

  return rest
}

const trimPersistedPlaybackData = (state: PlaybackDataReducer) => {
  const keys = Object.keys(state.data)

  if (keys.length < MAX_PERSISTED_VIDEOS) {
    return state.data
  }

  return removeFromPlaybackData(state.data, keys[0])
}

const updatePlaybackData = (state: PlaybackDataReducer, playbackData: PersistedPlaybackData): PlaybackDataReducer => {
  if (!playbackData || !playbackData.fileId) {
    return state
  }

  const trimmedData = trimPersistedPlaybackData(state)

  return {
    ...state,
    data: {
      // the purpose of removing (instead of replacing) is the queue implementation
      ...removeFromPlaybackData(trimmedData, playbackData.fileId),
      [playbackData.fileId]: {
        ..._get(state, 'data', playbackData.fileId),
        ...playbackData,
        subtitles: {
          ..._get(state, 'data', playbackData.fileId, 'subtitles'),
          ..._get(playbackData, 'subtitles'),
        },
      },
    },
  }
}

export default createReducer(initialState, {
  [actions.Types.UPDATE_PLAYBACK_DATA]: (
    state: PlaybackDataReducer,
    action: ReturnType<typeof actions.updatePlaybackData>,
  ): PlaybackDataReducer => updatePlaybackData(state, action.payload),
})
