import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'

export const setSubtitleTracks = (
  player: ReactPlayer,
  selectedSubtitlesLabel: string,
  activeSubtitles: VTTCue[],
  setActiveSubtitles: (subtitles: VTTCue[]) => void,
) => {
  if (player) {
    const textTracks = (player.getInternalPlayer() as HTMLVideoElement).textTracks

    if (textTracks && textTracks.length) {
      // not an array > for loop is required here
      for (let i = 0; i < textTracks.length; i++) {
        // set active track
        textTracks[i].mode = textTracks[i].label === selectedSubtitlesLabel ? 'showing' : 'hidden'

        // set custom subtitles (currently showing)
        if (textTracks[i].label === selectedSubtitlesLabel) {
          const newCues = (activeSubtitles || []).reduce((agg, cue) => {
            let replacedCue

            for (let j = 0; j < textTracks[i].cues.length; j++) {
              if (textTracks[i].cues[j].startTime >= cue.startTime && textTracks[i].cues[j].endTime <= cue.endTime) {
                replacedCue = textTracks[i].cues[j]
              }
            }

            return replacedCue ? [...agg, replacedCue] : agg
          }, [])

          if (newCues) {
            setActiveSubtitles(newCues)
          }
        } else if (!selectedSubtitlesLabel) {
          setActiveSubtitles([])
        }
      }
    }
  }
}

const createCustomSubtitles = (
  player: ReactPlayer,
  selectedSubtitlesLabel: string,
  setActiveSubtitles: (subtitles: VTTCue[] | TextTrackCue[]) => void,
) => {
  if (player && player.getInternalPlayer()) {
    const textTracks = (player.getInternalPlayer() as HTMLVideoElement).textTracks

    if (textTracks && textTracks.length) {
      // not an array > for loop is required here
      for (let i = 0; i < textTracks.length; i++) {
        textTracks[i].oncuechange = ev => {
          if (selectedSubtitlesLabel === (ev.target as TextTrack).label) {
            const activeCues = (ev.target as TextTrack).activeCues
            const newSubtitles: (VTTCue | TextTrackCue)[] = []

            for (let j = 0; j < activeCues.length; j++) {
              newSubtitles.push(activeCues[j])
            }
            setActiveSubtitles(newSubtitles)
          }
        }
      }
    }
  }
}

export const useCustomSubtitles = (player: ReactPlayer, selectedSubtitlesLabel: string) => {
  const [activeSubtitles, setActiveSubtitles] = useState([])

  useEffect(() => createCustomSubtitles(player, selectedSubtitlesLabel, setActiveSubtitles))

  useEffect(() => {
    setSubtitleTracks(player, selectedSubtitlesLabel, activeSubtitles, setActiveSubtitles)
  }, [selectedSubtitlesLabel])

  return activeSubtitles
}
