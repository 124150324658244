import VolumeDown from 'components/icons/volume-down'
import VolumeMuted from 'components/icons/volume-muted'
import VolumeOff from 'components/icons/volume-off'
import VolumeUp from 'components/icons/volume-up'
import { VOLUME_SETTING_STEP_PERCENT } from 'components/KeyboardControls'
import { AllowedKeys } from 'components/KeyboardEventHandler'
import { DEFAULT_VOLUME } from 'components/Player'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { isIOS } from 'utils/browserChecks'
import Slider from '../Slider'
import { FloatingMuteButton, MuteButton, SliderWrapper, Wrapper } from './styled'

const VOLUME_BREAKPOINTS = {
  down: 0.3,
  up: 0.6,
}

interface Props {
  isMuted: boolean
  mute: (mute: boolean) => void
  volume: number
  setVolume: (volume: number) => void
  mutedByDefault?: boolean
  onSpacePress?: () => void
}

const Volume = ({ isMuted, mute, volume, setVolume, mutedByDefault, onSpacePress }: Props) => {
  const { t } = useTranslation()
  const [touched, setTouched] = useState(false)
  const [isFocusWithin, setFocusWithin] = useState(false)

  const [muteButton, setMuteButtonRef] = useState(null)
  const muteButtonRef = useCallback((node: HTMLButtonElement) => {
    !muteButton && setMuteButtonRef(node)
  }, [])

  const [floatingButton, setFloatingButtonRef] = useState(null)
  const floatingButtonRef = useCallback((node: HTMLButtonElement) => {
    !floatingButton && setFloatingButtonRef(node)
  }, [])

  const handleSlideEnd = (volume: number) => {
    if (!volume || Number.isNaN(volume)) {
      mute(true)
    }
    if (volume && isMuted) {
      mute(false)
    }
    setVolume(Number.isNaN(volume) ? 0 : volume)
  }

  const handleKeyDown = (keyboardEvent: SyntheticEvent<Element, KeyboardEvent>) => {
    if ([keyboardEvent.nativeEvent.code, keyboardEvent.nativeEvent.key].includes(AllowedKeys.ARROW_UP)) {
      keyboardEvent.nativeEvent.preventDefault()
      handleSlideEnd(Math.min(volume + VOLUME_SETTING_STEP_PERCENT, 1))
    }
    if ([keyboardEvent.nativeEvent.code, keyboardEvent.nativeEvent.key].includes(AllowedKeys.ARROW_DOWN)) {
      keyboardEvent.nativeEvent.preventDefault()
      handleSlideEnd(Math.max(volume - VOLUME_SETTING_STEP_PERCENT, 0))
    }
    if (
      [AllowedKeys.SPACE, AllowedKeys.SPACE_CHAR].some(key => [keyboardEvent.nativeEvent.code, keyboardEvent.nativeEvent.key].includes(key))
    ) {
      keyboardEvent.nativeEvent.preventDefault()
      onSpacePress && onSpacePress()
    }
  }

  const getIconClassName = () => {
    if (volume < VOLUME_BREAKPOINTS.down) {
      return 'vp-volume-btn-0'
    } else if (volume < VOLUME_BREAKPOINTS.up) {
      return 'vp-volume-btn-1'
    } else {
      return 'vp-volume-btn-2'
    }
  }

  const renderIcon = () => {
    if (volume < VOLUME_BREAKPOINTS.down) {
      return <VolumeMuted />
    } else if (volume < VOLUME_BREAKPOINTS.up) {
      return <VolumeDown />
    } else {
      return <VolumeUp />
    }
  }

  if (isMuted && mutedByDefault && !touched) {
    return (
      <FloatingMuteButton
        ref={floatingButtonRef}
        onClick={() => {
          floatingButton && floatingButton.blur && floatingButton.blur()
          setTouched(true)
          mute(!isMuted)
        }}
        onKeyDown={e => {
          if ([AllowedKeys.SPACE, AllowedKeys.SPACE_CHAR].some(key => [e.nativeEvent.code, e.nativeEvent.key].includes(key))) {
            e.nativeEvent.stopImmediatePropagation()
            mute(!isMuted)
          } else {
            handleKeyDown(e)
          }
        }}
        className="vp-volume-btn-floating"
        data-testid="vp-volume-btn-floating"
        data-tip={t('Unmute')}
      >
        <VolumeOff />
      </FloatingMuteButton>
    )
  }

  return (
    <Wrapper isFocusWithin={isFocusWithin} data-testid="vp-volume-container" className="vp-volume-container">
      <MuteButton
        ref={muteButtonRef}
        data-testid="vp-volume-btn"
        className={`vp-volume-btn ${isMuted ? 'vp-volume-btn-unmute' : 'vp-volume-btn-mute'} ${isMuted ? '' : getIconClassName()}`}
        onFocus={() => setFocusWithin(true)}
        onBlur={() => setFocusWithin(false)}
        onClick={() => {
          muteButton && muteButton.blur && muteButton.blur()
          if (isMuted && volume === 0) {
            setVolume(DEFAULT_VOLUME)
          }

          mute(!isMuted)
        }}
        onKeyDown={e => {
          if ([AllowedKeys.SPACE, AllowedKeys.SPACE_CHAR].some(key => [e.nativeEvent.code, e.nativeEvent.key].includes(key))) {
            e.nativeEvent.stopImmediatePropagation()
            mute(!isMuted)

            if (e.target && e.target instanceof HTMLButtonElement) {
              e.target.blur && e.target.blur()
            }
          } else {
            handleKeyDown(e)
          }
        }}
        data-tip={isMuted ? t('Unmute') : t('Mute')}
      >
        {!isMuted && renderIcon()}
        {isMuted && <VolumeOff />}
      </MuteButton>
      {!isIOS() && (
        <SliderWrapper
          data-tip={t('Volume Level')}
          className={`vp-volume-dropdown ${isMuted ? 'vp-volume-dropdown-unmute' : 'vp-volume-dropdown-mute'}`}
        >
          <Slider
            onFocusChange={setFocusWithin}
            domain={[0, 1]}
            value={volume}
            onSlideEnd={handleSlideEnd}
            vertical
            onKeyDown={handleKeyDown}
          />
        </SliderWrapper>
      )}
    </Wrapper>
  )
}

export default Volume
