import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  color: #fff;
  transition: 1s all ease-in-out;
  font-size: 11px;

  bottom: 40px;
  right: 10px;
`

export const IconWrapper = styled.div`
  margin-left: 10px;
  color: #fff;
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
  @media only screen and (max-width: 400px) {
    display: none;
  }
`

export const TextWrapper = styled.div`
  display: inline-block;
  z-index: 9999;
  text-align: left;
  font-size: 11px;
  vertical-align: middle;
`

export const SkipBtn = styled.button`
  margin: 0;
  color: #fff;
  min-width: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.floatingControlsBg};
  border: 1px solid #fff;
  cursor: pointer;
  transition: 1s all ease-in-out, 0.25s background linear;
  box-shadow: 0px 0px 30px 10px rgba(255, 255, 255, 0);
  padding: 2px 5px;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    padding: 5px 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.floatingControlsHoverBg};
  }
`
