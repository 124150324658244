import { createGlobalStyle, DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  colors: {
    controlsPanel: 'rgba(51, 51, 51, 0.7)',
    controlBg: 'rgba(255, 255, 255, 0)',
    controlHoverBg: 'rgba(255, 255, 255, 0.05)',
    seekBarBg: 'rgba(115,133,159,.5)',
    seekBarCompleteBg: '#ccc',
    seekBarCaret: 'rgba(0,0,0,0.3)',
    unselectedText: '#aaa',
    floatingControlsBg: 'rgba(51, 51, 51, 0.5)',
    floatingControlsHoverBg: 'rgba(100, 100, 100, 0.5)',
    playCTAFill: 'rgba(255, 255, 255, 0.7)',
    overlay: 'rgba(51, 51, 51, 0.5)',
    timeIndicatorBg: 'rgba(0, 0, 0, .9)',
    tooltipBg: 'rgba(0, 0, 0, .8)',
    headlineColor: '#e6e6e6',
    closeBtnColor: '#666',
  },
  layout: {
    sizes: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
}

export const GlobalStyle = createGlobalStyle``

export default theme
