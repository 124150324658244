import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const PlayArrow = ({ size = 20 }: Props) => {
  return (
    <SVG data-testid="play-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <path d="M16 10v28l22-14z" />
    </SVG>
  )
}

export default PlayArrow
