import React, { SyntheticEvent, useCallback, useState } from 'react'
import { Handles, Rail, Slider as CompoundSlider } from 'react-compound-slider'
import { useEventListener } from 'utils/hooks'
import Handle from './_components/Handle'
import SliderRail from './_components/SliderRail'
import { SliderWrapper } from './styled'

const SLIDER_STEP = 0.00001

interface Props {
  domain: number[]
  value: number
  onSlideEnd?: (value: number) => void
  onSlideStart?: () => void
  onChange?: (value: number) => void
  vertical?: boolean
  isClickable?: boolean
  innerRef?: (el: Element) => void
  disabled?: boolean
  animationDuration?: number
  onKeyDown?: (event: SyntheticEvent<Element, KeyboardEvent>) => void
  showInitialHint?: boolean
  onFocusChange?: (focused: boolean) => void
}

const Slider = ({
  domain,
  value,
  onSlideEnd,
  onSlideStart,
  onChange,
  vertical,
  isClickable = true,
  innerRef,
  disabled,
  animationDuration,
  onKeyDown,
  showInitialHint,
  onFocusChange,
}: Props) => {
  const [touched, setTouched] = useState(false)
  const [sliderRef, setSliderRef] = useState(null)
  const reference = useCallback((node: Element) => {
    if (!sliderRef) {
      setSliderRef(node)
      innerRef && innerRef(node)
    }
  }, [])

  useEventListener(
    'touchmove',
    e => {
      e.preventDefault()
    },
    sliderRef,
    { passive: false },
  )

  return (
    <SliderWrapper
      ref={reference}
      data-testid="vp-slider-container"
      className={`vp-slider-container ${vertical ? 'vp-slider-container-vertical' : 'vp-slider-container-horizontal'}`}
    >
      <CompoundSlider
        className="vp-slider"
        disabled={disabled || !isClickable}
        domain={domain}
        values={[value]}
        step={SLIDER_STEP}
        onSlideStart={isClickable ? onSlideStart : undefined}
        onSlideEnd={([value]: number[]) => {
          onSlideEnd && isClickable && onSlideEnd(value)
        }}
        onChange={([value]: number[]) => {
          onChange && isClickable && onChange(value)
        }}
        vertical={vertical}
        reversed={vertical}
        rootProps={{
          onTouchMove: e => e.stopPropagation(),
        }}
      >
        <Rail>
          {({ getRailProps }) => (
            <SliderRail
              setTouched={setTouched}
              touched={touched}
              onSlideStart={onSlideStart}
              vertical={vertical}
              completed={value * 100}
              getRailProps={getRailProps}
              animationDuration={animationDuration}
            />
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              {(handles || []).map(handle => (
                <Handle
                  key={handle.id}
                  domain={domain}
                  getHandleProps={getHandleProps}
                  handle={handle}
                  vertical={vertical}
                  animationDuration={animationDuration}
                  onKeyDown={onKeyDown}
                  showInitialHint={showInitialHint}
                  onFocusChange={onFocusChange}
                />
              ))}
            </>
          )}
        </Handles>
      </CompoundSlider>
    </SliderWrapper>
  )
}

export default Slider
