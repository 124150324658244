import VastPlayer from '@jakubMenda/vast-player'
import { _get } from 'utils/get'

export const resolveClickThroughUrl = (adPlayer: VastPlayer) => {
  const ads = _get(adPlayer, 'vast', 'ads')

  if (!ads || ads.length !== 1) {
    return null
  }

  const creatives = _get(ads, 0, 'creatives')

  if (!creatives || !creatives.length) {
    return null
  }

  const objWithClickThrough = creatives.find(creative => !!creative.videoClicks)

  if (!objWithClickThrough) {
    return null
  }

  return _get(objWithClickThrough, 'videoClicks', 'clickThrough')
}
