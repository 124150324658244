import '@babel/polyfill'
import { Config } from '@types'
import React from 'react'
import ReactDOM from 'react-dom'
import { _get } from 'utils/get'
import App from './templates/App'

export const init = (appConfig: Config) => ReactDOM.render(<App config={appConfig} />, _get(appConfig, 'videoPlayerConfig', 'videoElement'))

export default { init }

if (process.env.NODE_ENV !== 'production') {
  const config = (window as any).config
  init(config)
}
