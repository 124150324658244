import CloseIcon from 'components/icons/close'
import KeyboardControls, { AllowedKeys } from 'components/KeyboardEventHandler'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Buttons, CancelButton, CloseButton, Headline, Message, OkButton, Overlay, Wrapper } from './styled'

interface Props {
  visible: boolean
  headline?: string
  message: string
  cancelText?: string
  okText?: string
  onOK?: () => void
  onCancel?: () => void
  overlayClassName?: string
  dialogClassName?: string
}

enum AnimationState {
  HIDDEN = 'hidden',
  HIDING = 'hiding',
  VISIBLE = 'visible',
  SHOWING = 'showing',
}

const Dialog = ({ visible, message, onCancel, cancelText, okText, onOK, overlayClassName, dialogClassName, headline }: Props) => {
  const { t } = useTranslation()
  const [animationState, runAnimation] = useState(AnimationState.HIDDEN)

  useEffect(() => {
    if (visible && animationState === AnimationState.HIDDEN) {
      runAnimation(AnimationState.SHOWING)
      const timeout = setTimeout(() => runAnimation(AnimationState.VISIBLE), 250)
      return () => clearTimeout(timeout)
    } else if (!visible && animationState === AnimationState.VISIBLE) {
      runAnimation(AnimationState.HIDING)
      const timeout = setTimeout(() => runAnimation(AnimationState.HIDDEN), 250)
      return () => clearTimeout(timeout)
    }
    return undefined
  }, [visible])

  if (!visible && animationState === AnimationState.HIDDEN) {
    return null
  }

  const isShowing = [AnimationState.VISIBLE, AnimationState.SHOWING].includes(animationState)

  return (
    <Overlay visible={isShowing} className={`vp-dialog-overlay ${overlayClassName || ''} ${isShowing ? 'vp-dialog-overlay-visible' : ''}`}>
      <Wrapper
        data-testid="vp-dialog"
        className={`vp-dialog ${dialogClassName || ''} ${isShowing ? 'vp-dialog-visible' : ''}`}
        visible={isShowing}
      >
        <CloseButton data-testid="vp-dialog-close-btn" className="vp-dialog-close-btn" onClick={onCancel}>
          <CloseIcon size={15} />
        </CloseButton>
        {headline && <Headline className="vp-dialog-headline">{headline}</Headline>}
        <Message className="vp-error-dialog-message">{message}</Message>
        <Buttons>
          {onOK && (
            <OkButton className="c-button vp-dialog-ok-btn" onClick={onOK}>
              {okText || t('Sure')}
            </OkButton>
          )}
          {onCancel && !!cancelText && (
            <CancelButton className="c-button vp-dialog-cancel-btn" onClick={onCancel}>
              {cancelText || t('Cancel')}
            </CancelButton>
          )}
        </Buttons>
      </Wrapper>
      <KeyboardControls
        handlers={[
          {
            keyCode: AllowedKeys.ESC,
            callback: onCancel,
          },
        ]}
        onlyOnBodyElement
      />
    </Overlay>
  )
}

export default Dialog
