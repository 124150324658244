import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const VolumeDown = ({ size = 16 }: Props) => {
  return (
    <SVG data-testid="unmute-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <path d="M37 24c0-3.53-2.04-6.58-5-8.05v16.11c2.96-1.48 5-4.53 5-8.06zm-27-6v12h8l10 10V8L18 18h-8z" />
    </SVG>
  )
}

export default VolumeDown
