import Dialog from 'components/Dialog'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { unsetGlobalError } from 'store/modules/globalPlayerData/actions'
import { selectGlobalError } from 'store/modules/globalPlayerData/selectors'
import { Redux } from '../../@types'

interface Props {
  error: ReturnType<typeof selectGlobalError>
  unsetGlobalError: typeof unsetGlobalError
}

const ErrorDialog = ({ error, unsetGlobalError }: Props) => {
  const { t } = useTranslation()

  error && console.error(error)
  return (
    <Dialog
      visible={!!error}
      message={t('The media could not be loaded, either because the server or network failed or because the format is not supported')}
      onCancel={() => unsetGlobalError()}
      overlayClassName="vp-error-dialog"
    />
  )
}

export default connect(
  (state: Redux) => ({
    error: selectGlobalError(state),
  }),
  { unsetGlobalError },
)(ErrorDialog)
