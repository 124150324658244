import React from 'react'
import { GetRailProps } from 'react-compound-slider'
import { ClickableRail, CompletedRail, StyledRail } from '../styled'

interface Props {
  getRailProps: GetRailProps
  completed: number
  vertical?: boolean
  onSlideStart?: () => void
  setTouched?: (touched: boolean) => void
  touched?: boolean
  animationDuration?: number
}

const SliderRail = ({ getRailProps, completed, vertical, onSlideStart, touched, setTouched, animationDuration }: Props) => {
  const { onMouseDown, onTouchStart, ...rest } = getRailProps() as any

  const handleMouseDown = e => {
    onMouseDown(e)

    if (!touched && onSlideStart) {
      onSlideStart()
    }

    setTouched && setTouched(false)
  }

  const handleTouchStart = e => {
    setTouched && setTouched(true)
    onTouchStart(e)
  }

  return (
    <>
      <StyledRail
        animationDuration={animationDuration}
        vertical={vertical}
        className={`vp-slider-rail ${vertical ? 'vp-slider-rail-vertical' : 'vp-slider-rail-horizontal'}`}
      />
      <CompletedRail
        vertical={vertical}
        className={`vp-slider-rail-complete ${vertical ? 'vp-slider-rail-complete-vertical' : 'vp-slider-rail-complete-horizontal'}`}
        percent={completed}
        animationDuration={animationDuration}
      />
      <ClickableRail
        data-testid="vp-slider-rail-clickable"
        vertical={vertical}
        className={`vp-slider-rail-clickable ${vertical ? 'vp-slider-rail-clickable-vertical' : 'vp-slider-rail-clickable-horizontal'}`}
        {...rest}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      />
    </>
  )
}

export default SliderRail
