import { SEEK_STEP_SECONDS } from 'components/KeyboardControls'
import { AllowedKeys } from 'components/KeyboardEventHandler'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import Slider from '../Slider'
import TimeIndicator from '../TimeIndicator'
import { Wrapper } from './styled'

interface Props {
  seekPosition: number
  onSeekTo?: (position: number) => void
  isClickable?: boolean
  videoLength: number
  onSeekStart?: () => void
  isSeeking?: boolean
  animationDuration?: number
  onSpacePress?: () => void
  showInitialHint?: boolean
}

const SeekBar = ({
  seekPosition,
  onSeekTo,
  isClickable = true,
  videoLength,
  onSeekStart,
  isSeeking,
  animationDuration = 250,
  onSpacePress,
  showInitialHint,
}: Props) => {
  const [tooltipPosition, setTooltipPosition] = useState(0)
  const [visible, setVisibility] = useState(false)
  const [hoveredProgress, setHoveredProgress] = useState(0)
  const [slider, setSliderRef] = useState(null)

  const sliderRef = useCallback((node: HTMLDivElement) => {
    if (!slider) setSliderRef(node)
  }, [])

  const handleMouseMove = (e: any) => {
    if (slider && visible) {
      const rect = slider.getBoundingClientRect()
      const position = e.pageX - rect.left
      setTooltipPosition(position)
      const progress = position / rect.width
      setHoveredProgress(progress >= 0 ? progress : 0)
    }
  }

  const handleKeyboardSeek = (seconds: number) => {
    const currentTime = videoLength * seekPosition
    const modified = currentTime + seconds

    if (modified > videoLength) {
      onSeekTo && onSeekTo(1)
    } else if (modified < 0) {
      onSeekTo && onSeekTo(0)
    } else {
      onSeekTo && onSeekTo(modified / videoLength)
    }
  }

  const handleKeyDown = (keyboardEvent: SyntheticEvent<Element, KeyboardEvent>) => {
    if ([keyboardEvent.nativeEvent.code, keyboardEvent.nativeEvent.key].includes(AllowedKeys.ARROW_RIGHT)) {
      keyboardEvent.nativeEvent.preventDefault()
      handleKeyboardSeek(SEEK_STEP_SECONDS)
    }
    if ([keyboardEvent.nativeEvent.code, keyboardEvent.nativeEvent.key].includes(AllowedKeys.ARROW_LEFT)) {
      keyboardEvent.nativeEvent.preventDefault()
      handleKeyboardSeek(-SEEK_STEP_SECONDS)
    }
    if (
      [AllowedKeys.SPACE, AllowedKeys.SPACE_CHAR].some(key => [keyboardEvent.nativeEvent.code, keyboardEvent.nativeEvent.key].includes(key))
    ) {
      keyboardEvent.nativeEvent.preventDefault()
      onSpacePress && onSpacePress()
    }
  }

  return (
    <Wrapper
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setVisibility(true)}
      onMouseLeave={() => setVisibility(false)}
      className="vp-seekbar"
      data-testid="vp-seekbar"
    >
      <Slider
        domain={[0, 1]}
        value={seekPosition}
        disabled={!onSeekTo}
        onSlideEnd={(position: number) => {
          onSeekTo && onSeekTo(position)
        }}
        onSlideStart={() => {
          onSeekStart && onSeekStart()
        }}
        isClickable={isClickable}
        innerRef={sliderRef}
        animationDuration={animationDuration}
        onKeyDown={handleKeyDown}
        showInitialHint={showInitialHint}
      />
      {visible && <TimeIndicator tooltipPosition={tooltipPosition} time={hoveredProgress * videoLength} />}
    </Wrapper>
  )
}

export default SeekBar
