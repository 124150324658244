import Countdown from 'components/Countdown'
import PayAndSkip from 'components/PayAndSkip'
import React, { useEffect, useState } from 'react'
import { Overlay } from './styled'

interface Props {
  initialized: boolean
  onCountdownEnd: () => void
  onPayAndSkipClick: () => void
  onCountdownClick: () => void
  isPreviewOver: boolean
}

const PreviewControls = ({ initialized, onCountdownEnd, onPayAndSkipClick, onCountdownClick, isPreviewOver }: Props) => {
  const [countdownEnded, setCountdownEnded] = useState(isPreviewOver)

  useEffect(() => {
    if (isPreviewOver) {
      setCountdownEnded(true)
    }
  }, [isPreviewOver])

  const handleCountdownEnd = () => {
    onCountdownEnd()
    setCountdownEnded(true)
  }

  return (
    <>
      <Countdown
        isPreviewOver={isPreviewOver}
        initialized={initialized}
        onCountdownEnd={handleCountdownEnd}
        onClick={onCountdownClick}
        countdownStopped={countdownEnded}
      />
      <PayAndSkip onClick={onPayAndSkipClick} countdownEnded={countdownEnded} />
      {countdownEnded && <Overlay className="vp-overlay" />}
    </>
  )
}

export default PreviewControls
