import styled from 'styled-components'

export const VastContainer = styled.div`
  width: 100%;
  height: 100%;

  video {
    position: relative;
  }
`

export const VastWrapper = styled.div`
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  background: #000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: stretch;
  cursor: pointer;
  z-index: 100;
`
