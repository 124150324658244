import React from 'react'
import { Wrapper } from './styled'

// TODO
const Loader = () => {
  return (
    <Wrapper className="vp-loader" data-testid="vp-loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  )
}

export default Loader
