import { ControlWrapper } from 'components/Controls/styled'
import styled from 'styled-components'

export const Wrapper = styled(ControlWrapper)`
  width: 25px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    width: 40px;
  }
`
