import { TrackProps } from 'react-player'
import { Actions, Ads, VideoSource } from '../../../@types'

export enum VideoType {
  LIVE = 'live',
  PAID = 'withoutPreview',
  WITH_PREVIEW = 'withPreview',
}

export interface CurrentVideoReducer {
  isLoading: boolean
  error: Error
  data: LiveVideo | PaidVideo | VideoWithPreview
}

interface CommonData {
  fileId: string
  hasCredit: boolean
  alreadyCharged: boolean
  autoplay: boolean
  sources: VideoSource[]
  type: VideoType
  subtitlesUrl?: string
  slideshowElement?: HTMLElement
  subtitles?: TrackProps[]
}

export interface LiveVideo extends CommonData {
  type: VideoType.LIVE
  ads?: Ads
}

export interface PaidVideo extends CommonData {
  type: VideoType.PAID
  fileSize: string
  getCheckURL?: string
  watchRefreshURL?: string
  watchExpire?: number
  actions: Actions
  ads?: undefined
  watchURL?: string
}

export interface VideoWithPreview extends CommonData {
  type: VideoType.WITH_PREVIEW
  fileSize: string
  getCheckURL?: string
  watchRefreshURL?: string
  watchExpire?: number
  watchURL?: string
  actions: Actions
  preview: Preview
  remainingPreviewTime?: number
  ads?: Ads
}

export interface Preview {
  fileSize: string
  getPreviewURL: string
  previewLength?: number
  expiresIn?: number
  streamPreviewUrl?: string
  streamPreviewSubtitles?: {
    [label: string]: string
  }
}

export interface RefreshResponse {
  watchUrl: string
}

export interface CheckResponse {
  checkUrl: string | null
  loggedUserId: number | null
  hasEnoughCredit: boolean
}

export interface GetPreviewSuccessResponse {
  streamPreviewUrl: string
  maxPreviewLength: number
  streamPreviewSubtitles: {
    [lang: string]: string
  }
}

export interface GetPreviewErrorResponse {
  errorNo: number
  error: string
}

export interface SubtitlesResponse {
  subtitles: {
    [lang: string]: string
  }
}
