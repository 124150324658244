import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const ExitFullScreenIcon = ({ size = 22 }: Props) => {
  return (
    <SVG data-testid="exit-fullscreen-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <path d="M10 32h6v6h4V28H10v4zm6-16h-6v4h10V10h-4v6zm12 22h4v-6h6v-4H28v10zm4-22v-6h-4v10h10v-4h-6z" />
    </SVG>
  )
}

export default ExitFullScreenIcon
