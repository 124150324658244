import { useEffect, useState } from 'react'
import { LiveVideo, PaidVideo, VideoType, VideoWithPreview } from 'store/modules/currentVideo/@types'
import { tryPurchaseVideo } from 'store/modules/currentVideo/actions'
import { updatePlaybackData } from 'store/modules/playbackData/actions'

export const usePreview = (
  onCountdownEnd: () => void,
  currentVideoData: LiveVideo | PaidVideo | VideoWithPreview,
  setInitialized: (initialized: boolean) => void,
  purchaseVideo: typeof tryPurchaseVideo,
  loggedIn: boolean,
  onKickBack: () => void,
  update: typeof updatePlaybackData,
  fileId: string,
  isPlaying: boolean,
  setIsPlaying: (isPlaying: boolean) => void,
  shouldPlayPreview: boolean,
) => {
  const [controlsHidden, setControlsHidden] = useState(false)
  const [isPreviewOver, setIsPreviewOver] = useState(false)

  // force pause when preview has ended
  // mainly because of the case when seek ends just after the countdown end > player tries to autoplay
  useEffect(() => {
    if (isPreviewOver && isPlaying && shouldPlayPreview) {
      setIsPlaying(false)
    }
  }, [isPlaying])

  const handleCountdownEnd = () => {
    setControlsHidden(true)
    setIsPreviewOver(true)
    onCountdownEnd()
  }

  const handlePayAndSkip = () => {
    if (currentVideoData && currentVideoData.type !== VideoType.LIVE) {
      setInitialized(false)

      purchaseVideo({
        onSuccess: () => {
          setControlsHidden(false)
        },
        onUserNotLoggedIn: () => {
          handleCountdownEnd()
        },
        omitConfirmDialog: true,
      })
    }
  }

  const handleCountdownClick = () => {
    // reset seek position
    update({ fileId, seekPosition: 0 })

    // refresh to start over again
    location.reload()
  }

  return {
    controlsHidden,
    setControlsHidden,
    isPreviewOver,
    handleCountdownEnd,
    handlePayAndSkip,
    handleCountdownClick,
  }
}
