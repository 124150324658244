import i18next from 'i18next'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { LocaleConfig } from '../@types'

export const initI18n = ({ addPath, loadPath, language }: LocaleConfig) => {
  i18next
    .use(XHR)
    .use(initReactI18next)
    .init({
      lng: language || 'en',
      backend: {
        loadPath,
        addPath,
      },
      interpolation: {
        escapeValue: false,
      },
      saveMissing: !!addPath,
      simplifyPluralSuffix: false,
      returnNull: false,
      react: {
        wait: true,
      },
    })
}
