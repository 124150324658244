import React from 'react'
import { formatSecondsToHHMMSS } from 'utils/time'
import { Caret, Text, Wrapper } from './styled'

interface Props {
  tooltipPosition: number
  time: number
}

export default ({ tooltipPosition, time }: Props) => {
  return (
    <Wrapper data-testid="vp-time-indicator" tooltipPosition={tooltipPosition} className="vp-time-indicator">
      <Text className="vp-time-indicator-text">{formatSecondsToHHMMSS(time)}</Text>
      <Caret className="vp-time-indicator-caret" />
    </Wrapper>
  )
}
