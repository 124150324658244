import { useEffect, useState } from 'react'

interface Params {
  shouldPlayPreview: boolean
  isPlaying: boolean
  hitPreviewStartUrl: () => void
}

export const useMonitoring = ({ shouldPlayPreview, isPlaying, hitPreviewStartUrl }: Params) => {
  const [wasPreviewStartHit, setWasPreviewStartHit] = useState(false)

  useEffect(() => {
    if (isPlaying && shouldPlayPreview && !wasPreviewStartHit) {
      hitPreviewStartUrl()
      setWasPreviewStartHit(true)
    }
  }, [isPlaying])
}
