import { useEffect, useState } from 'react'

export const useAirPlay = player => {
  const [airPlayAvailable, setAirplayAvailability] = useState(false)

  useEffect(() => {
    if (player && player.getInternalPlayer() && !airPlayAvailable) {
      if ((window as any).WebKitPlaybackTargetAvailabilityEvent) {
        player.getInternalPlayer().addEventListener('webkitplaybacktargetavailabilitychanged', e => {
          setAirplayAvailability(e.availability === 'available')
        })
      }
    }
  }, [player, player && player.getInternalPlayer()])

  const handleAirPlayClick = () => {
    if (player && player.getInternalPlayer() && player.getInternalPlayer().webkitShowPlaybackTargetPicker) {
      player.getInternalPlayer().webkitShowPlaybackTargetPicker()
    }
  }

  return { airPlayAvailable, handleAirPlayClick }
}
