import { combineReducers } from 'redux'
import currentVideo from './modules/currentVideo/reducer'
import global from './modules/globalPlayerData/reducer'
import playback from './modules/playbackData/reducer'
import subtitlesStyles from './modules/subtitlesStyles/reducer'

export default combineReducers({
  currentVideo,
  global,
  playback,
  subtitlesStyles,
})
