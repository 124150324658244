import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { LiveVideo, PaidVideo, VideoWithPreview } from 'store/modules/currentVideo/@types'

export enum AutoplayMode {
  NORMAL = 'normal',
  SILENT = 'silent',
  PAUSED = 'paused',
}

export const useAutoplay = (
  player: ReactPlayer,
  currentVideoData: LiveVideo | PaidVideo | VideoWithPreview,
  mute: (mute: boolean) => void,
  setIsPlaying: (isPlaying: boolean) => void,
  shouldPlay: boolean,
  initialized: boolean,
  haveBeenUnmuted: boolean,
) => {
  const [autoplayMode, setAutoplayMode] = useState(null)

  useEffect(() => {
    if (initialized) {
      setAutoplayMode(AutoplayMode.NORMAL)
    }
  }, [initialized])

  useEffect(() => {
    const shouldAutoplay = currentVideoData && (currentVideoData.autoplay || currentVideoData.slideshowElement)

    if (shouldPlay && shouldAutoplay && player) {
      const internalPlayer = player.getInternalPlayer() as HTMLVideoElement
      const promise = internalPlayer && internalPlayer.play ? internalPlayer.play() : undefined

      if (promise !== undefined) {
        promise
          .catch(() => {
            switch (autoplayMode) {
              // the player is not playing > try to turn off the sound
              case AutoplayMode.NORMAL:
                // in case user has unmuted the ad, better stop the video entirely than mute again
                if (haveBeenUnmuted) {
                  setAutoplayMode(AutoplayMode.PAUSED)
                  mute(false)
                } else {
                  mute(true)
                  setAutoplayMode(AutoplayMode.SILENT)
                }
                break
              // muted and still not playing > pause it and unmute
              case AutoplayMode.SILENT:
                setAutoplayMode(AutoplayMode.PAUSED)
                mute(false)
                break
              // The video is paused > do nothing
              default:
                break
            }
          })
          .then(() => {
            // do nothing - will play automatically
          })
      }
    }
  }, [autoplayMode, currentVideoData.autoplay, player, shouldPlay])

  return { autoplayMode, setAutoplayMode }
}
