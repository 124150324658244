import { DEFAULT_EXPIRATION_TIME } from 'components/PreviewLinkExpirationWatcher'
import { parseSubtitlesResponse } from 'store/modules/currentVideo/utils'
import createReducer from 'utils/createReducer'
import { _get } from 'utils/get'
import { CurrentVideoReducer, VideoType } from './@types'
import * as actions from './actions'

export const initialState: CurrentVideoReducer = {
  isLoading: false,
  error: null,
  data: null,
}

export default createReducer(initialState, {
  [actions.Types.UPDATE_CURRENT_VIDEO]: (
    state: CurrentVideoReducer,
    action: ReturnType<typeof actions.updateCurrentVideo>,
  ): CurrentVideoReducer => {
    if (!action.payload || !Object.entries(action.payload).length) {
      return state
    }

    return {
      ...state,
      data: action.payload,
    }
  },
  [actions.Types.SET_REMAINING_PREVIEW_TIME]: (
    state: CurrentVideoReducer,
    action: ReturnType<typeof actions.setRemainingPreviewTime>,
  ): CurrentVideoReducer => {
    // The ugly condition has to be like this for typescript to stop complaining
    if (!state.data || state.data.type === VideoType.LIVE || state.data.type === VideoType.PAID) {
      return state
    }

    return {
      ...state,
      data: {
        ...state.data,
        remainingPreviewTime: action.payload.remainingTimeSeconds,
      },
    }
  },
  [actions.Types.PURCHASE_VIDEO]: (state: CurrentVideoReducer): CurrentVideoReducer => {
    return {
      ...state,
      isLoading: true,
    }
  },
  [actions.Types.PURCHASE_VIDEO_SUCCESS]: (
    state: CurrentVideoReducer,
    action: ReturnType<typeof actions.purchaseVideoSuccess>,
  ): CurrentVideoReducer => {
    const newWatchUrl = _get(action, 'payload', 'watchURL')

    return {
      ...state,
      isLoading: false,
      data: {
        ...state.data,
        // TODO current design doesnt support multiple qualities on paid videos, perhaps to implement later right here
        sources: newWatchUrl ? [{ URL: newWatchUrl, quality: '1' }] : state.data.sources,
        alreadyCharged: true,
      },
    }
  },
  [actions.Types.SET_PREVIEW_EXPIRES_IN]: (
    state: CurrentVideoReducer,
    action: ReturnType<typeof actions.setExpiresIn>,
  ): CurrentVideoReducer => {
    // The ugly condition has to be like this for typescript to stop complaining
    if (!state.data || state.data.type === VideoType.LIVE || state.data.type === VideoType.PAID) {
      return state
    }

    const { expiresIn } = action.payload

    return {
      ...state,
      data: {
        ...state.data,
        preview: {
          ...state.data.preview,
          expiresIn,
        },
      },
    }
  },
  [actions.Types.GET_PREVIEW_LINK]: (state: CurrentVideoReducer): CurrentVideoReducer => {
    // The ugly condition has to be like this for typescript to stop complaining
    if (!state.data || state.data.type === VideoType.LIVE || state.data.type === VideoType.PAID) {
      return state
    }

    return {
      ...state,
      isLoading: true,
    }
  },
  [actions.Types.GET_PREVIEW_LINK_SUCCESS]: (
    state: CurrentVideoReducer,
    action: ReturnType<typeof actions.getPreviewLinkSuccess>,
  ): CurrentVideoReducer => {
    // The ugly condition has to be like this for typescript to stop complaining
    if (!state.data || state.data.type === VideoType.LIVE || state.data.type === VideoType.PAID) {
      return state
    }

    const { maxPreviewLength, ...rest } = action.payload

    return {
      ...state,
      isLoading: false,
      data: {
        ...state.data,
        remainingPreviewTime: maxPreviewLength,
        preview: {
          ...state.data.preview,
          previewLength: maxPreviewLength,
          // TODO might be sent from the server, defaults to two minutes for now
          expiresIn: DEFAULT_EXPIRATION_TIME,
          ...rest,
        },
      },
    }
  },
  [actions.Types.CANCEL_PREVIEW_FETCH]: (state: CurrentVideoReducer): CurrentVideoReducer => {
    return {
      ...state,
      isLoading: false,
    }
  },
  [actions.Types.GET_SUBTITLES]: (state: CurrentVideoReducer): CurrentVideoReducer => {
    return {
      ...state,
      isLoading: true,
    }
  },
  [actions.Types.GET_SUBTITLES_SUCCESS]: (
    state: CurrentVideoReducer,
    action: ReturnType<typeof actions.getSubtitlesSuccess>,
  ): CurrentVideoReducer => {
    const { subtitles } = action.payload

    return {
      ...state,
      isLoading: false,
      data: {
        ...state.data,
        subtitles: parseSubtitlesResponse(subtitles),
      },
    }
  },
  [actions.Types.GET_SUBTITLES_ERROR]: (
    state: CurrentVideoReducer,
    action: ReturnType<typeof actions.getSubtitlesError>,
  ): CurrentVideoReducer => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error,
    }
  },
  [actions.Types.SET_AUTOPLAY]: (state: CurrentVideoReducer, action: ReturnType<typeof actions.setAutoplay>): CurrentVideoReducer => {
    return {
      ...state,
      data: {
        ...state.data,
        autoplay: action.payload.autoplay,
      },
    }
  },
})
