import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatSecondsToHHMMSS } from 'utils/time'
import { Slash, TimeSpan, Wrapper } from './styled'

interface Props {
  videoLength: number
  seekPosition: number
  step: number
}

const Progress = ({ videoLength = 0, seekPosition = 0, step }: Props) => {
  const { t } = useTranslation()
  const currentTimeInSeconds = seekPosition * videoLength
  const rounded = Math.round(currentTimeInSeconds)
  const current = formatSecondsToHHMMSS(rounded)
  const remaining = formatSecondsToHHMMSS(videoLength - rounded)

  return (
    <Wrapper data-testid="vp-progress-indicator" className="vp-progress-indicator">
      <TimeSpan className="vp-progress-indicator-current" data-tip={t('Current Time')} data-testid="vp-progress-indicator-current">
        {current}
      </TimeSpan>
      <Slash className="vp-progress-indicator-slash">/</Slash>
      <TimeSpan className="vp-progress-indicator-total" data-tip={t('Duration')}>
        {' '}
        {remaining}
      </TimeSpan>
    </Wrapper>
  )
}

export default Progress
