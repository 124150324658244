import { ControlWrapper } from 'components/Controls/styled'
import styled, { DefaultTheme, ThemeProps } from 'styled-components'

interface WrapperProps extends ThemeProps<DefaultTheme> {
  isFocusWithin?: boolean
}

export const Wrapper = styled.div`
  width: 25px;
  height: 100%;
  display: inline-block;
  vertical-align: middle;

  & > div {
    opacity: ${({ isFocusWithin }: WrapperProps) => (isFocusWithin ? 1 : 0)};
    height: ${({ isFocusWithin }: WrapperProps) => (isFocusWithin ? '100px' : '0')};
  }

  &:hover > div,
  & > button:focus + div {
    display: block;
    opacity: 1;
    height: 100px;
  }

  &:focus-within > div {
    display: block;
    opacity: 1;
    height: 100px;
  }

  @media screen and (min-width: ${({ theme }: WrapperProps) => theme.layout.sizes.md}px) {
    width: 40px;
  }
`

export const MuteButton = styled(ControlWrapper)`
  width: 25px;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    width: 40px;
  }
`

export const SliderWrapper = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.controlsPanel};
  width: 25px;
  transition: 0.25s all linear;
  bottom: 30px;
  padding: 10px 0;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    width: 40px;
  }
`

export const FloatingMuteButton = styled.button`
  margin: 0;
  padding: 0;
  color: #fff;
  min-width: 0;
  position: absolute;
  bottom: 40px;
  left: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.floatingControlsBg};
  transition: 0.25s background linear;
  cursor: pointer;
  border: 1px solid #fff;
  box-shadow: 0px 0px 30px 10px rgba(255, 255, 255, 1);

  &:hover {
    background: ${({ theme }) => theme.colors.floatingControlsHoverBg};
  }
`
