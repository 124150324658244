import { DialogType } from 'components/ConnectedDialog'
import moment from 'moment'
import qs from 'qs'
import { Action } from 'redux'
import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import {
  CheckResponse,
  CurrentVideoReducer,
  GetPreviewErrorResponse,
  GetPreviewSuccessResponse,
  LiveVideo,
  PaidVideo,
  Preview,
  RefreshResponse,
  SubtitlesResponse,
  VideoType,
  VideoWithPreview,
} from 'store/modules/currentVideo/@types'
import { selectCurrentVideo, selectPreview } from 'store/modules/currentVideo/selectors'
import { parseSubtitlesResponse } from 'store/modules/currentVideo/utils'
import { openDialog, setGlobalError } from 'store/modules/globalPlayerData/actions'
import { selectLoggedIn } from 'store/modules/globalPlayerData/selectors'
import { updatePlaybackData } from 'store/modules/playbackData/actions'
import { selectPlaybackData } from 'store/modules/playbackData/selectors'
import { _get } from 'utils/get'
import request from 'utils/request'
import * as actions from './actions'
import {
  cancelPreviewFetch,
  checkAvailability,
  checkPermissions,
  checkPermissionsError,
  checkPermissionsSuccess,
  getPreviewLinkError,
  getPreviewLinkSuccess,
  getSubtitlesError,
  getSubtitlesSuccess,
  purchaseVideoError,
  purchaseVideoSuccess,
  requestPurchaseVideo,
} from './actions'

// handles the case when the video was already paid for
export function* handleAlreadyChargedVideo(onSuccess: () => void) {
  // @TODO: this might need refactoring (time zones)
  const currentTimestamp = moment().valueOf()
  const currentVideo = yield select(selectCurrentVideo)
  const videoUrl = _get(currentVideo, 'sources', 0, 'URL') || _get(currentVideo, 'watchURL')

  if (currentVideo.alreadyCharged) {
    if (currentVideo.watchExpire > currentTimestamp) {
      const hasQuery = videoUrl.includes('?')
      const baseUrl = hasQuery ? videoUrl.split('?')[0] : videoUrl
      const queryString = hasQuery ? videoUrl.split('?')[1] : ''
      const queryParams = qs.parse(queryString)

      const modifiedWatchUrl = `${baseUrl}?${qs.stringify({ ...queryParams, nocharge: 1 })}`

      yield put(purchaseVideoSuccess(modifiedWatchUrl))
    } else {
      const currentVideo: CurrentVideoReducer['data'] = yield select(selectCurrentVideo)

      // should not happen
      if (currentVideo.type !== VideoType.LIVE && currentVideo.watchRefreshURL) {
        const response: RefreshResponse = yield call(request, currentVideo.watchRefreshURL, {
          method: 'GET',
        })

        yield put(purchaseVideoSuccess(response.watchUrl))
      }
    }
    onSuccess && onSuccess()
  } else {
    // not charged > catch and continue flow
    throw new Error('not-charged')
  }
}

// performs watch link refresh (the vid purchasing)
export function* purchase() {
  try {
    const currentVideo = yield select(selectCurrentVideo)
    const videoUrl = _get(currentVideo, 'sources', 0, 'URL') || _get(currentVideo, 'watchURL')
    // this means we just use source in the config, no modifications
    yield put(purchaseVideoSuccess(videoUrl))
  } catch (e) {
    yield put(setGlobalError(e))
    yield put(purchaseVideoError(e))
  }
}

// This starts the whole purchase flow
export function* watchTryVideoPurchase() {
  yield takeLatest(actions.Types.TRY_PURCHASE_VIDEO, function* handle(action: ReturnType<typeof actions.tryPurchaseVideo>) {
    try {
      const onSuccess = _get(action, 'payload', 'onSuccess')
      const onCancel = _get(action, 'payload', 'onCancel')
      const onUserNotLoggedIn = _get(action, 'payload', 'onUserNotLoggedIn')
      const omitConfirmDialog = _get(action, 'payload', 'omitConfirmDialog')

      yield put(checkPermissions({ onUserNotLoggedIn }))

      const resultAction: Action = yield take([actions.Types.CHECK_PERMISSIONS_SUCCESS, actions.Types.CHECK_PERMISSIONS_ERROR])

      switch (resultAction.type) {
        case actions.Types.CHECK_PERMISSIONS_SUCCESS:
          yield put(checkAvailability())
          yield put(requestPurchaseVideo({ onSuccess, omitConfirmDialog, onCancel }))
          break
        case actions.Types.CHECK_PERMISSIONS_ERROR:
        default:
          return
      }
    } catch (e) {
      yield put(setGlobalError(e))
    }
  })
}

export function* watchCheckPermissions() {
  yield takeLatest(actions.Types.CHECK_PERMISSIONS, function* handle(action: ReturnType<typeof actions.checkPermissions>) {
    try {
      const onSuccess = _get(action, 'payload', 'onSuccess')
      const onUserNotLoggedIn = _get(action, 'payload', 'onUserNotLoggedIn')
      const currentVideo: CurrentVideoReducer['data'] = yield select(selectCurrentVideo)
      const loggedIn: boolean = yield select(selectLoggedIn)

      // should not happen
      if (currentVideo.type === VideoType.LIVE) {
        return
      }

      const { actions } = currentVideo

      console.log('my-debug-string')
      if (!loggedIn && !currentVideo.alreadyCharged) {
        onUserNotLoggedIn && onUserNotLoggedIn()
        actions && actions.onUserNotLoggedIn ? actions.onUserNotLoggedIn() : yield put(openDialog(DialogType.LOGIN_DIALOG))
        throw new Error('not-logged-in')
      }

      if (!currentVideo.alreadyCharged) {
        if (!currentVideo.hasCredit) {
          actions.onLowCredit ? actions.onLowCredit() : yield put(openDialog(DialogType.LOW_CREDIT_DIALOG))
          throw new Error('low-credit')
        }
      }

      yield put(checkPermissionsSuccess())
      onSuccess && onSuccess()
    } catch (e) {
      yield put(checkPermissionsError(e))
    }
  })
}

// checks for video availability, if user is logged in and if he has the credit for the video
export function* watchCheckAvailability() {
  yield takeLatest(actions.Types.CHECK_AVAILABILITY, function* handle(action: ReturnType<typeof actions.checkAvailability>) {
    try {
      const onSuccess = _get(action, 'payload', 'onSuccess')
      const currentVideo: CurrentVideoReducer['data'] = yield select(selectCurrentVideo)

      // should not happen anyway - we dont check for availability on live videos
      if (currentVideo.type === VideoType.LIVE) {
        onSuccess && onSuccess()
        return
      }

      const { getCheckURL, actions } = currentVideo

      if (getCheckURL) {
        const { loggedUserId, hasEnoughCredit, checkUrl }: CheckResponse = yield request(getCheckURL, {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
            credentials: 'same-origin',
          },
        })

        // kick out users who arent logged in
        if (!loggedUserId) {
          // prefer handling from the outside config
          if (actions && actions.onUserNotLoggedIn) {
            actions.onUserNotLoggedIn()
          } else {
            // otherwise show dialog
            yield put(openDialog(DialogType.LOGIN_DIALOG))
          }
          return
        }

        // kick out users with not enough credit
        if (!hasEnoughCredit) {
          // prefer handling from the outside config
          if (actions && actions.onLowCredit) {
            actions.onLowCredit()
          } else {
            // otherwise show dialog
            yield put(openDialog(DialogType.LOW_CREDIT_DIALOG))
          }
          return
        }

        // check for cors if possible
        if (checkUrl) {
          yield request(checkUrl, {
            method: 'GET',
          })
        }
      }

      // all clear - continue
      onSuccess && onSuccess()
    } catch (e) {
      yield put(setGlobalError(e))
      yield put(purchaseVideoError(e))
    }
  })
}

// initiates the whole purchasing flow
export function* watchRequestPurchase() {
  yield takeLatest(actions.Types.REQUEST_PURCHASE_VIDEO, function* handle(action: ReturnType<typeof actions.requestPurchaseVideo>) {
    try {
      const onSuccess = _get(action, 'payload', 'onSuccess')
      const onCancel = _get(action, 'payload', 'onCancel')
      const omitConfirmDialog = _get(action, 'payload', 'omitConfirmDialog')

      // first, try if the user has already purchased the video
      try {
        yield handleAlreadyChargedVideo(onSuccess)
        // not charged or expired > ask for purchase confirmation
      } catch (e) {
        if (omitConfirmDialog) {
          yield purchase()
          const successCallback = _get(action, 'payload', 'onSuccess') || onSuccess
          successCallback && successCallback()
        } else {
          yield put(openDialog(DialogType.PURCHASE_DIALOG, { onOK: onSuccess, onCancel: onCancel }))

          // user agrees
          yield takeLatest(actions.Types.PURCHASE_VIDEO, function* handle(action: ReturnType<typeof actions.purchaseVideo>) {
            // This means that video has not been alreadyCharged, so just grab source.push to <video> (onSuccess action) and
            // backend will charge automatically
            yield purchase()
            const successCallback = _get(action, 'payload', 'onSuccess') || onSuccess
            successCallback && successCallback()
          })
        }
      }
    } catch (e) {
      yield put(setGlobalError(e))
      yield put(purchaseVideoError(e))
    }
  })
}

export function* watchGetPreview() {
  yield takeLatest(actions.Types.GET_PREVIEW_LINK, function* handle(action: ReturnType<typeof actions.getPreviewLink>) {
    try {
      const onSuccess = _get(action, 'payload', 'onSuccess')
      const preview: Preview = yield select(selectPreview)

      if (preview.streamPreviewUrl) {
        onSuccess && onSuccess()
        yield put(cancelPreviewFetch())
      } else {
        const isError = (res: GetPreviewSuccessResponse | GetPreviewErrorResponse): res is GetPreviewErrorResponse =>
          !!(res as GetPreviewErrorResponse).error

        const response: GetPreviewSuccessResponse | GetPreviewErrorResponse = yield call(request, preview.getPreviewURL, {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
            credentials: 'same-origin',
          },
        })

        // api can respond with success code with the error in response body
        if (isError(response)) {
          throw new Error(response.error)
        }

        onSuccess && onSuccess()
        yield put(getPreviewLinkSuccess(response))
      }
    } catch (e) {
      yield put(setGlobalError(e))
      yield put(getPreviewLinkError(e))
    }
  })
}

function* watchGetSubtitles() {
  yield takeLatest(actions.Types.GET_SUBTITLES, function* handle(action: ReturnType<typeof actions.getSubtitles>) {
    try {
      const currentVideo: LiveVideo | PaidVideo | VideoWithPreview = yield select(selectCurrentVideo)

      const response: SubtitlesResponse = yield request(currentVideo.subtitlesUrl, {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache',
          credentials: 'same-origin',
        },
      })

      // if any results, set the first item in the list of subtitles as default (if the preference is not cached already)
      const firstSubtitlesLabel = _get(parseSubtitlesResponse(_get(response, 'subtitles')), 0, 'label')
      const playbackData = yield select(selectPlaybackData(currentVideo.fileId))

      if (firstSubtitlesLabel && !_get(playbackData, 'subtitles', 'selectedSubtitlesLabel')) {
        yield put(
          updatePlaybackData({
            fileId: currentVideo.fileId,
            subtitles: {
              selectedSubtitlesLabel: firstSubtitlesLabel,
            },
          }),
        )
      }

      yield put(getSubtitlesSuccess(_get(response, 'subtitles')))
    } catch (e) {
      yield put(getSubtitlesError(e))
      yield put(setGlobalError(e))
    }
  })
}

export default function* currentVideoSaga() {
  yield fork(watchCheckAvailability)
  yield fork(watchRequestPurchase)
  yield fork(watchGetPreview)
  yield fork(watchCheckPermissions)
  yield fork(watchTryVideoPurchase)
  yield fork(watchGetSubtitles)
}
