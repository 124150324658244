import Controls from 'components/Controls'
import KeyboardControls from 'components/KeyboardControls'
import Loader from 'components/Loader'
import PlayCTA from 'components/PlayCTA'
import { useAirPlay } from 'components/Player/hooks/useAirPlay'
import { AutoplayMode, useAutoplay } from 'components/Player/hooks/useAutoplay'
import { useCustomSubtitles } from 'components/Player/hooks/useCustomSubtitles'
import { useFullScreen } from 'components/Player/hooks/useFullScreen'
import { useMonitoring } from 'components/Player/hooks/useMonitoring'
import { usePreview } from 'components/Player/hooks/usePreview'
import { useSafetySwitch } from 'components/Player/hooks/useSafetySwitch'
import { useSubtitles } from 'components/Player/hooks/useSubtitles'
import {
  getCurrentVideoSource,
  getMediaBoxClassNames,
  getPlayerClassNames,
  getPlayerConfig,
  isAllowedToPlay,
} from 'components/Player/utils'
import PreviewControls from 'components/PreviewControls'
import React, { useCallback, useEffect, useState } from 'react'
import ReactPlayer, { TrackProps } from 'react-player'
import { connect } from 'react-redux'
import Tooltip from 'react-tooltip'
import { LiveVideo, PaidVideo, VideoType, VideoWithPreview } from 'store/modules/currentVideo/@types'
import { getSubtitles, tryPurchaseVideo } from 'store/modules/currentVideo/actions'
import { selectCurrentVideo, selectCurrentVideoLoading, selectSubtitles } from 'store/modules/currentVideo/selectors'
import { hitPreviewStartUrl, setControlsVisibility, setGlobalError } from 'store/modules/globalPlayerData/actions'
import { selectControlsVisibility, selectLoggedIn } from 'store/modules/globalPlayerData/selectors'
import { updatePlaybackData } from 'store/modules/playbackData/actions'
import { selectPlaybackData } from 'store/modules/playbackData/selectors'
import { hasIssuesWithFullscreen, isEdge } from 'utils/browserChecks'
import { _get } from 'utils/get'
import { useEventListener } from 'utils/hooks'
import { Redux } from '../../@types'
import { PlayerWrapper } from './styled'

const TOOLTIP_DELAY = 1000
export const DEFAULT_VOLUME = 0.8
const PROGRESS_STEP = 250

interface Props {
  shouldPlay: boolean
  updatePlaybackData?: typeof updatePlaybackData
  setGlobalError?: typeof setGlobalError
  tryPurchaseVideo?: typeof tryPurchaseVideo
  currentVideoData?: LiveVideo | PaidVideo | VideoWithPreview
  fileId?: string
  volume?: number
  muted?: boolean
  seekPosition?: number
  loggedIn?: boolean
  videoQuality?: string
  isLoading?: boolean
  onStart?: () => void
  onPlay?: () => void
  subtitles?: TrackProps[]
  getSubtitles: typeof getSubtitles
  selectedSubtitlesLabel: string
  onKickBack?: () => void
  controlsVisible?: boolean
  setControlsVisibility?: typeof setControlsVisibility
  hasBeenUnmuted: boolean
  hitPreviewStartUrl: typeof hitPreviewStartUrl
}

const VideoPlayer = ({
  currentVideoData,
  fileId,
  volume,
  muted,
  seekPosition,
  updatePlaybackData,
  setGlobalError,
  onStart,
  subtitles,
  onPlay,
  loggedIn,
  videoQuality,
  isLoading,
  tryPurchaseVideo,
  getSubtitles,
  selectedSubtitlesLabel,
  onKickBack,
  setControlsVisibility,
  controlsVisible,
  shouldPlay,
  hasBeenUnmuted,
  hitPreviewStartUrl,
}: Props) => {
  const [initialized, setInitialized] = useState(false)
  const [ready, setReady] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [isSeeking, setIsSeeking] = useState(false)
  const [sourceChanged, setSourceChanged] = useState(false)
  const [wasPlayingBeforeSeek, setWasPlayingBeforeSeek] = useState(false)
  const [isVideoOver, setIsVideoOver] = useState()

  const shouldPlayPreview =
    currentVideoData &&
    currentVideoData.type === VideoType.WITH_PREVIEW &&
    _get(currentVideoData, 'preview') &&
    !_get(currentVideoData, 'alreadyCharged')

  // set player reference
  const [player, setPlayerRef] = useState(null)
  const playerRef = useCallback((node: ReactPlayer) => {
    !player && setPlayerRef(node)
  }, [])

  // set player wrapper (outer div) reference
  const [wrapper, setWrapperRef] = useState(null)
  const wrapperRef = useCallback((node: Element) => {
    !wrapper && setWrapperRef(node)
  }, [])

  // fetch subtitles
  useSubtitles(subtitles, currentVideoData.subtitlesUrl, getSubtitles)

  // get actions for handling fullscreen
  const { isFullScreen, handleSetFullScreen, handleFullScreen } = useFullScreen(player ? player.getInternalPlayer() : undefined, wrapper)

  // handle autoplay
  const { autoplayMode, setAutoplayMode } = useAutoplay(
    player,
    currentVideoData,
    (muted: boolean) => updatePlaybackData({ fileId, muted }),
    setIsPlaying,
    shouldPlay,
    initialized,
    hasBeenUnmuted,
  )

  if (hasIssuesWithFullscreen()) {
    useSafetySwitch(isFullScreen, handleSetFullScreen)
  }

  // provides actions and flags useful for video with preview
  const { handleCountdownClick, handlePayAndSkip, handleCountdownEnd, controlsHidden, setControlsHidden, isPreviewOver } = usePreview(
    () => {
      handleSetFullScreen(true)
      handlePlay(false)
    },
    currentVideoData,
    setInitialized,
    tryPurchaseVideo,
    loggedIn,
    onKickBack,
    updatePlaybackData,
    fileId,
    isPlaying,
    setIsPlaying,
    shouldPlayPreview,
  )

  useCustomSubtitles(player, selectedSubtitlesLabel)

  useMonitoring({ isPlaying, shouldPlayPreview, hitPreviewStartUrl })

  const { airPlayAvailable, handleAirPlayClick } = useAirPlay(player)

  // disable Chrome Cast control
  useEffect(() => {
    const internalPlayer = player && player.getInternalPlayer()

    if (internalPlayer && internalPlayer.disableRemotePlayback) {
      internalPlayer.disableRemotePlayback = false
    }
  }, [player && player.getInternalPlayer() && player.getInternalPlayer().disableRemotePlayback])

  // Player unmounts on video loading > this resets the state
  useEffect(() => {
    if (isLoading) {
      setReady(false)
      setInitialized(false)
      setControlsHidden(false)
      setIsPlaying(false)
      setDuration(0)
      setAutoplayMode(null)
    }
  }, [isLoading])

  useEffect(() => {
    videoQuality && setSourceChanged(true)
  }, [videoQuality])

  const handleAutoplayAfterPreview = () => {
    if (!shouldPlayPreview && currentVideoData.type === VideoType.WITH_PREVIEW) {
      if (player && player.getSecondsLoaded() < 1) {
        // @NOTE wait for video to load
        setTimeout(() => {
          handleAutoplayAfterPreview()
        }, 50)
      } else {
        handlePlay(true)
      }
    }
  }

  // autoplay when preview has ended
  useEffect(handleAutoplayAfterPreview, [shouldPlayPreview])

  // @NOTE fallback to prevent player freeze
  const handleSeekEndFallback = (shouldPlay: boolean) => {
    if (shouldPlay) {
      const seeking = player && player.getInternalPlayer() && player.getInternalPlayer().seeking

      if (!seeking) {
        setIsVideoOver(isVideoOver => {
          if (isVideoOver !== true) {
            setIsPlaying(true)
          }

          return isVideoOver
        })
      } else {
        setTimeout(() => {
          handleSeekEndFallback(shouldPlay)
        }, 100)
      }
    }
  }

  // fixes (replaces) lack of onReady event on ios - handles seek
  useEffect(() => {
    if (player && player.getInternalPlayer()) {
      const isSeeking = !!player.getInternalPlayer().seeking
      setIsSeeking(isSeeking)

      // @NOTE causing error on edge, but absence would result in playing
      // a few random seconds of the video during seeking on iOS
      if (!isEdge()) {
        if (isSeeking) {
          setWasPlayingBeforeSeek(isPlaying || wasPlayingBeforeSeek)
          setIsPlaying(false)
          handleSeekEndFallback(isPlaying)
        } else if (!isPlaying) {
          setIsPlaying(wasPlayingBeforeSeek)
          setWasPlayingBeforeSeek(false)
        }
      }
    }
  }, [player && player.getInternalPlayer() && player.getInternalPlayer().seeking])

  // fixes (replaces) lack of onReady event on ios - handles change of quality
  useEffect(() => {
    if (player && player.getInternalPlayer() && player.getInternalPlayer().readyState) {
      setIsSeeking(false)
      if (!initialized) {
        handleInit()
      }
    }
  }, [
    initialized,
    player && player.getInternalPlayer() && player.getInternalPlayer().readyState,
    player && player.getInternalPlayer() && player.getInternalPlayer().seeking,
  ])

  const handleInit = () => {
    if (isSeeking) {
      setIsSeeking(false)
    }

    if (player && !initialized) {
      if (seekPosition < 0.95 && !shouldPlayPreview) {
        player.seekTo(seekPosition)
        setIsSeeking(true)
      } else {
        updatePlaybackData({ fileId, seekPosition: 0 })
      }
      setInitialized(true)
    }
  }

  useEffect(() => {
    if (seekPosition < 1 && isVideoOver) {
      setIsVideoOver(false)
    }
  }, [seekPosition])

  const handlePlay = (play: boolean) => {
    if (isVideoOver) {
      handleSeekTo(0)
      setIsVideoOver(false)
      setIsPlaying(true)
    } else if (!play) {
      setIsPlaying(play)
    } else if (isAllowedToPlay(currentVideoData, loggedIn, isPreviewOver)) {
      if (sourceChanged && player) {
        // @NOTE workaround for ios (cant seek when video wasn't played before, so handleInit seek is not working)
        const promise = player.getInternalPlayer() && player.getInternalPlayer().play()

        // @NOTE old Opera does simply not return a Promise
        if (promise instanceof Promise) {
          promise.then(() => {
            player.seekTo(seekPosition)
            setSourceChanged(false)
            setIsPlaying(true)
            setWasPlayingBeforeSeek(false)
          })
        } else {
          player.seekTo(seekPosition)
          setSourceChanged(false)
          setIsPlaying(true)
          setWasPlayingBeforeSeek(false)
        }
      } else {
        setIsPlaying(play)
        setWasPlayingBeforeSeek(false)
      }
    } else if (currentVideoData.type === VideoType.PAID) {
      tryPurchaseVideo()
    }
    handleFullScreen()
  }

  // Persist seek position continuously
  const handleProgress = ({ played }) => {
    if (isPlaying && played && !isSeeking) {
      updatePlaybackData({ fileId, seekPosition: Math.round(played * 100000) / 100000 })
    }
  }

  const handleEnd = () => {
    player && player.getInternalPlayer().load()
    handleSetFullScreen(true)
    setIsVideoOver(true)
  }

  const handleError = error => {
    // we filter out DOM exception when play is called too soon or is interrupted
    if (String(error).includes('play')) return
    if (error && !error.message && !_get(error, 'target', 'error', 'message')) return
    setGlobalError(error)
  }

  const handleSeekTo = (seekPosition: number) => {
    // if we're going to seek, set isSeeking to FALSE
    // (some browsers cant determine seek end properly, TRUE state is mainly for dragging the slider handle)
    player && setIsSeeking(false)
    player && player.seekTo(seekPosition)

    // reflect this to redux
    updatePlaybackData({ fileId, seekPosition })
  }

  const handleVideoSourceChange = (videoQuality: string) => {
    updatePlaybackData({
      fileId,
      videoQuality,
    })

    setInitialized(false)
  }

  const handleTouchStart = e => {
    if ((e.target as HTMLElement).tagName === 'VIDEO' && !controlsVisible) {
      // just show the controls and dont pause the video (=dont trigger ReactPlayer onClick) on first tap
      if (!controlsVisible && isPlaying && 'ontouchstart' in document.documentElement) {
        e.preventDefault()
      }
      setControlsVisibility(true)
    }
  }

  // handleTouchStart wouldn't work properly passed as a prop in chrome
  // chrome handles events as passive by default - https://github.com/facebook/react/issues/8968
  useEventListener('touchstart', handleTouchStart, wrapper, { passive: false })

  if (!currentVideoData || isLoading || (!isAllowedToPlay(currentVideoData, loggedIn, isPreviewOver) && !isPreviewOver)) {
    if (shouldPlay) {
      return <Loader />
    }
    return null
  }

  if (![VideoType.LIVE, VideoType.WITH_PREVIEW].includes(currentVideoData.type) && !loggedIn) {
    return <PlayCTA onClick={() => handlePlay(true)} showText={!ready} />
  }

  const source = getCurrentVideoSource(currentVideoData, loggedIn, shouldPlayPreview, videoQuality, shouldPlay)
  const isPlayIconVisible = initialized && !isPlaying && !isSeeking && (!isPreviewOver || !shouldPlayPreview) && !wasPlayingBeforeSeek
  const isLoaderVisible = shouldPlay && (!initialized || isSeeking || wasPlayingBeforeSeek)

  return (
    <PlayerWrapper
      className={getMediaBoxClassNames(isFullScreen, isPlaying, isVideoOver)}
      ref={wrapperRef}
      controlsVisible={controlsVisible}
      isFullscreen={isFullScreen}
    >
      <ReactPlayer
        data-testid="react-player"
        className={getPlayerClassNames(isSeeking, controlsVisible, isPreviewOver)}
        ref={playerRef}
        url={source}
        playing={isPlaying}
        muted={muted}
        volume={volume}
        onDuration={setDuration}
        onClick={() => {
          if (controlsVisible || !('ontouchstart' in document.documentElement)) {
            handlePlay(!isPlaying)
          }
        }}
        progressInterval={PROGRESS_STEP}
        onProgress={handleProgress}
        onReady={handleInit}
        onStart={onStart}
        onEnded={handleEnd}
        onBuffer={() => setIsSeeking(true)}
        onBufferEnd={() => setIsSeeking(false)}
        onPlay={() => {
          setReady(true)
          handlePlay(true)
          onPlay && onPlay()
        }}
        onPause={() => handlePlay(false)}
        config={getPlayerConfig(subtitles, selectedSubtitlesLabel)}
        onError={handleError}
        width="100%"
        height="100%"
        playsinline
      />
      {!controlsHidden && (
        <Controls
          isPlaying={isPlaying}
          setIsPlaying={handlePlay}
          canSkip={false}
          onSkip={() => null}
          isFullScreen={isFullScreen}
          onSetFullScreen={handleSetFullScreen}
          isMuted={muted}
          mute={(muted: boolean) => updatePlaybackData({ fileId, muted })}
          volume={volume}
          setVolume={(volume: number) => updatePlaybackData({ fileId, volume })}
          seekPosition={seekPosition}
          onSeekTo={handleSeekTo}
          videoLength={duration}
          selectedQuality={videoQuality}
          sources={_get(currentVideoData, 'sources')}
          selectQuality={handleVideoSourceChange}
          mutedByDefault={ready && autoplayMode === AutoplayMode.SILENT}
          onSeekStart={() => setIsSeeking(true)}
          hasSubtitlesSettings={false}
          isSeeking={isSeeking}
          progressStep={PROGRESS_STEP}
          showInitialSeekBarHint={shouldPlayPreview && !isPreviewOver && initialized}
          isVideoOver={isVideoOver}
          airPlayAvailable={airPlayAvailable}
          onAirPlayClick={handleAirPlayClick}
        />
      )}
      <KeyboardControls isPlaying={isPlaying} setIsPlaying={handlePlay} duration={duration} fileId={fileId} handleSeekTo={handleSeekTo} />
      {isPlayIconVisible && <PlayCTA isVideoOver={isVideoOver} onClick={() => handlePlay(true)} showText={!ready} />}
      {initialized && shouldPlayPreview && (
        <PreviewControls
          initialized={ready}
          onCountdownEnd={handleCountdownEnd}
          onPayAndSkipClick={handlePayAndSkip}
          onCountdownClick={handleCountdownClick}
          isPreviewOver={isPreviewOver}
        />
      )}
      {isLoaderVisible && <Loader />}
      <Tooltip className="vp-tooltip" delayShow={TOOLTIP_DELAY} globalEventOff="click" />
    </PlayerWrapper>
  )
}

export default connect(
  (state: Redux) => {
    const currentVideoData = selectCurrentVideo(state)
    const playbackData = selectPlaybackData(_get(currentVideoData, 'fileId'))(state)
    const volume = _get(playbackData, 'volume')

    return {
      currentVideoData,
      fileId: _get(currentVideoData, 'fileId'),
      volume: volume || volume === 0 ? volume : DEFAULT_VOLUME,
      muted: _get(playbackData, 'muted') || false,
      seekPosition: _get(playbackData, 'seekPosition') || 0,
      loggedIn: selectLoggedIn(state),
      videoQuality: _get(playbackData, 'videoQuality') || _get((_get(currentVideoData, 'sources') || []).find(Boolean), 'quality'),
      isLoading: selectCurrentVideoLoading(state),
      subtitles: selectSubtitles(state),
      selectedSubtitlesLabel: _get(playbackData, 'subtitles', 'selectedSubtitlesLabel'),
      controlsVisible: selectControlsVisibility(state),
    }
  },
  {
    updatePlaybackData,
    setGlobalError,
    tryPurchaseVideo,
    getSubtitles,
    setControlsVisibility,
    hitPreviewStartUrl,
  },
)(VideoPlayer)
