import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const SkipIcon = ({ size = 22 }: Props) => {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      <path d="M12 36l17-12-17-12v24zm20-24v24h4V12h-4z" />
    </SVG>
  )
}

export default SkipIcon
