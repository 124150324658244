import { AnyAction, Reducer } from 'redux'
import mergeDeep from 'utils/merge-deep'

const INIT = '@@redux/INIT'

const isObject = item => item && typeof item === 'object' && !Array.isArray(item)

export default function createReducer(initialState: any, handlers: any): Reducer<any> {
  if (initialState === undefined) {
    throw new Error('undefined passed to `createReducer` as initial state.')
  }

  if (!(handlers === undefined) && !isObject(handlers)) {
    throw new Error('Invalid handlers object passed to `createReducer`')
  }

  return function reducer(state: any = initialState, action: AnyAction) {
    let resultState = state
    if (action.type === INIT) {
      if (initialState.asMutable) {
        // check of immutable object
        resultState = initialState.mergeDeep(state)
      } else {
        resultState = mergeDeep({}, initialState, state)
      }

      return resultState
    }

    if (handlers && handlers.hasOwnProperty(action.type)) {
      const handler = handlers[action.type]
      resultState = handler(state, action)
    }

    return resultState
  }
}
