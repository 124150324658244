import ConfigWatcher from 'components/ConfigWatcher'
import Loader from 'components/Loader'
import MainElement from 'components/MainElement'
import GlobalTheme, { GlobalStyle } from 'constants/GlobalTheme'
import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from 'store/configureStore'
import { ThemeProvider } from 'styled-components'
import { _get } from 'utils/get'
import * as locale from 'utils/locale'
import { Config } from '../../@types'

interface Props {
  config: Config
}

const App = ({ config }: Props) => {
  locale.initI18n(_get(config, 'videoPlayerConfig', 'locale'))

  return (
    <ThemeProvider theme={GlobalTheme}>
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <Suspense fallback={<Loader />}>
            <GlobalStyle />
            <ConfigWatcher config={config}></ConfigWatcher>
            <MainElement />
          </Suspense>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  )
}

export default process.env.NODE_ENV !== 'production' ? require('react-hot-loader').hot(module)(App) : App
