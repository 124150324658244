import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const AirPlay = ({ size = 20 }: Props) => {
  return (
    <SVG width={size} height={size} viewBox="0 0 48 48">
      <path d="M42 6H6c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h8v-4H6V10h36v24h-8v4h8c2.2 0 4-1.8 4-4V10c0-2.2-1.8-4-4-4zM12 44h24L24 32z" />
    </SVG>
  )
}

export default AirPlay
