import styled, { DefaultTheme, ThemeProps } from 'styled-components'

export enum PayAndSkipPosition {
  CENTERED = 'centered',
  BOTTOM_RIGHT = 'bottom_right',
}

interface WrapperProps extends ThemeProps<DefaultTheme> {
  position: PayAndSkipPosition
}

interface PayBtnProps extends ThemeProps<DefaultTheme> {
  highlighted: boolean
}

interface AdditionProps extends ThemeProps<DefaultTheme> {
  visible: boolean
}

export const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  color: #fff;
  transition: 1s all ease-in-out;
  font-size: 11px;
  max-width: 220px;

  ${({ position }: WrapperProps) => {
    switch (position) {
      case PayAndSkipPosition.CENTERED:
        return `
          bottom: 50%;
          right: 50%;
          transform: translate(50%, 50%);
        `
      case PayAndSkipPosition.BOTTOM_RIGHT:
      default:
        return `
          bottom: 40px;
          right: 10px;
        `
    }
  }}
`

export const PayBtn = styled.button`
  margin: 0 auto;
  color: #fff;
  min-width: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.tooltipBg};
  border: 1px solid #fff;
  cursor: pointer;
  transition: 1s all ease-in-out, 0.25s background linear;
  box-shadow: ${({ highlighted }: PayBtnProps) =>
    highlighted ? '0px 0px 30px 10px rgba(255,255,255,1)' : 'box-shadow: 0px 0px 30px 10px rgba(255,255,255,0)'};
  padding: 2px 5px;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    padding: 5px 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.floatingControlsHoverBg};
  }
`

export const IconWrapper = styled.div`
  margin-left: 10px;
  color: #fff;
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
`

export const TextWrapper = styled.div`
  text-align: left;
  font-size: 13px;
  display: inline-block;
  vertical-align: middle;
  padding: 5px;
  max-width: 130px;
`

export const Addition = styled.div`
  margin: 0 auto;
  position: absolute;
  bottom: -65px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 300px;
  background: ${({ theme }) => theme.colors.tooltipBg};
  border: 1px solid #fff;
  transition: 2s opacity linear;
  text-align: center;
  font-size: 13px;
  padding: 5px;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    bottom: -70px;
    width: 500px;
  }

  @keyframes appear {
    0% {
      display: none;
      opacity: 0;
    }
    25% {
      display: none;
    }
    50% {
      display: block;
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${({ visible }: AdditionProps) => {
    if (visible) {
      return `
        animation-name: appear;
        animation-duration: 2s;
      `
    } else {
      return 'display: none;'
    }
  }}
`
