import styled, { DefaultTheme, ThemeProps } from 'styled-components'

interface OverlayProps extends ThemeProps<DefaultTheme> {
  visible?: boolean
}

interface WrapperProps extends ThemeProps<DefaultTheme> {
  visible?: boolean
}

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.overlay};
  z-index: 100;
  transition: 0.25s opacity linear;
  opacity: ${({ visible }: OverlayProps) => (visible ? 1 : 0)};
`

export const Wrapper = styled.div`
  background-color: #fff;
  padding: 50px 20px 20px 20px;
  box-shadow: 2px 2px 20px 0px rgba(0, 0, 0, 0.5);
  z-index: 110;
  position: relative;
  max-width: 400px;
  min-width: 250px;
  transition: 0.25s all ease;
  top: ${({ visible }: WrapperProps) => (visible ? '50%' : '40%')};
  transform: translate(0, -50%);
  margin: 0 auto;
  color: black;
`

export const Headline = styled.h1`
  padding: 0 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.headlineColor};
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  text-align: left;
`

export const Message = styled.p`
  text-align: left;
  margin-bottom: 20px;
`

export const Buttons = styled.footer`
  text-align: center;
  margin-top: 20px;

  & > button + button {
    margin-left: 10px;
  }
`

export const CloseButton = styled.button`
  margin: 0;
  border: none;
  padding: 0;
  min-width: 0;
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.closeBtnColor};
`

export const OkButton = styled.button`
  margin: 0;
  color: #fff;
  min-width: 0;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  border: none;
  background-color: ${({ theme }) => theme.colors.controlsPanel};
  transition: all 0.25s linear;
  display: inline-block;
  vertical-align: middle;

  &:hover {
    background-color: ${({ theme }) => theme.colors.floatingControlsBg};
  }
`

export const CancelButton = styled.button`
  margin: 0;
  color: #fff;
  min-width: 0;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  border: none;
  background-color: transparent;
  transition: all 0.25s linear;
  display: inline-block;
  vertical-align: middle;

  &:hover {
    background-color: #eee;
  }
`
