import SkipIcon from 'components/icons/skip'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { VideoType } from 'store/modules/currentVideo/@types'
import { selectCurrentVideo } from 'store/modules/currentVideo/selectors'
import { Redux } from '../../@types'
import { Addition, IconWrapper, PayAndSkipPosition, PayBtn, TextWrapper, Wrapper } from './styled'

interface Props {
  fileSize: string
  onClick: () => void
  countdownEnded: boolean
}

const PayAndSkip = ({ countdownEnded, onClick, fileSize }: Props) => {
  const { t } = useTranslation()

  return (
    <Wrapper
      className={`vp-pay-and-skip-container ${countdownEnded ? 'vp-pay-and-skip-container-after-countdown' : ''}`}
      data-testid="vp-pay-and-skip-button"
      position={countdownEnded ? PayAndSkipPosition.CENTERED : PayAndSkipPosition.BOTTOM_RIGHT}
    >
      <PayBtn
        className={`vp-pay-and-skip-btn ${countdownEnded ? 'vp-pay-and-skip-btn-after-countdown' : ''}`}
        data-testid="vp-pay-and-skip-btn"
        highlighted={countdownEnded}
        onClick={onClick}
        onKeyDown={e => {
          e.nativeEvent.stopImmediatePropagation()
        }}
      >
        <TextWrapper className={`vp-pay-and-skip-text ${countdownEnded ? 'vp-pay-and-skip-text-after-countdown' : ''}`}>
          {fileSize ? t('Play full video for {{1}} credit', { 1: fileSize }) : t('Play full video for credit')}
        </TextWrapper>
        <IconWrapper className={`vp-pay-and-skip-icon ${countdownEnded ? 'vp-pay-and-skip-icon-after-countdown' : ''}`}>
          <SkipIcon size={40} />
        </IconWrapper>
      </PayBtn>
      <Addition
        data-testid="vp-pay-and-skip-addition"
        className={`vp-pay-and-skip-addition ${countdownEnded ? 'vp-pay-and-skip-addition-after-countdown' : ''}`}
        visible={countdownEnded}
      >
        {t('After payment, you can download or watch the video up to 24 hours for free')}
      </Addition>
    </Wrapper>
  )
}

export default connect((state: Redux) => {
  const currentVideo = selectCurrentVideo(state)
  return {
    fileSize: currentVideo.type !== VideoType.LIVE ? currentVideo.fileSize : null,
  }
})(PayAndSkip)
