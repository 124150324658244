import { parseCurrentVideoData, parseGlobalData, parsePlaybackData } from 'components/ConfigWatcher/utils'
import { configValidation } from 'components/ConfigWatcher/validation'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { updateCurrentVideo } from 'store/modules/currentVideo/actions'
import { setGlobalError, updateGlobalData } from 'store/modules/globalPlayerData/actions'
import { updatePlaybackData } from 'store/modules/playbackData/actions'
import { Config } from '../../@types'

interface Props {
  updatePlaybackData?: typeof updatePlaybackData
  updateGlobalData?: typeof updateGlobalData
  updateCurrentVideo?: typeof updateCurrentVideo
  setGlobalError?: typeof setGlobalError
  config: Config
  children?: any
}

const ConfigWatcher = ({ children, updatePlaybackData, updateGlobalData, updateCurrentVideo, config, setGlobalError }: Props) => {
  useEffect(() => {
    configValidation.validate(config, { strict: true })

    updatePlaybackData(parsePlaybackData(config.videoPlayerConfig))
    updateGlobalData(parseGlobalData(config.videoPlayerConfig, config.monitoring))
    updateCurrentVideo(
      parseCurrentVideoData(
        // Keep multi-line
        config.videoPlayerConfig,
        config.charge,
        config.preview,
        config.actions,
        config.ads,
      ),
    )
  }, [config])

  return children || null
}

export default connect(
  null,
  { updatePlaybackData, updateGlobalData, updateCurrentVideo, setGlobalError },
)(ConfigWatcher)
