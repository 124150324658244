import SkipIcon from 'components/icons/skip'
import { AllowedKeys } from 'components/KeyboardEventHandler'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Wrapper } from './styled'

interface Props {
  onSkip?: () => void
}

const Skip = ({ onSkip }: Props) => {
  const { t } = useTranslation()

  return (
    <Wrapper
      onKeyDown={e => {
        ;[AllowedKeys.SPACE, AllowedKeys.SPACE_CHAR].some(key => [e.nativeEvent.code, e.nativeEvent.key].includes(key)) &&
          e.nativeEvent.stopImmediatePropagation()
      }}
      data-testid="vp-skip-btn"
      data-tip={t('Next video')}
      className="vp-skip-btn"
      onClick={onSkip}
    >
      <SkipIcon />
    </Wrapper>
  )
}

export default Skip
