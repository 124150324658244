import createReducer from 'utils/createReducer'
import { Color, FontFamily, ShadowStyle, SubtitlesStylesReducer, Transparency } from './@types'
import * as actions from './actions'

export const initialState: SubtitlesStylesReducer = {
  fontColor: Color.WHITE,
  fontTransparency: Transparency.VISIBLE,
  background: Color.BLACK,
  backgroundTransparency: Transparency.VISIBLE,
  fontSize: 1,
  shadowStyle: ShadowStyle.NONE,
  fontFamily: FontFamily.SANS_SERIF,
}

export default createReducer(initialState, {
  [actions.Types.UPDATE_SUBTITLES_STYLE]: (
    state: SubtitlesStylesReducer,
    action: ReturnType<typeof actions.updateSubtitlesStyle>,
  ): SubtitlesStylesReducer => {
    return {
      ...state,
      ...action.payload,
    }
  },
  [actions.Types.RESET_SUBTITLES_STYLE]: (state: SubtitlesStylesReducer): SubtitlesStylesReducer => {
    return {
      ...initialState,
    }
  },
})
