import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)

export const supportsCustomSubtitles = () => {
  return browser.satisfies({
    android: {
      chrome: '>41',
      firefox: '>68',
      samsung_internet: '>9',
    },
    windows: {
      chrome: '>41',
      firefox: '>68',
      opera: '>24',
    },
    macOS: {
      chrome: '>41',
      firefox: '>68',
      opera: '>24',
    },
  })
}

export const isIOS = () => {
  return browser.satisfies({
    iOS: {
      chrome: '>1',
      safari: '>1',
    },
  })
}

export const isEdge = () =>
  browser.satisfies({
    edge: '>1',
  })

export const hasIssuesWithFullscreen = () =>
  browser.satisfies({
    linux: {
      firefox: '<34',
    },
  })

export const isChromeBelowV61 = () =>
  browser.satisfies({
    chrome: '<61',
  })
