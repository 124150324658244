import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { GetHandleProps, SliderItem } from 'react-compound-slider'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Tooltip from 'react-tooltip'
import { setControlsVisibility, setControlsVisibilityCountdown } from 'store/modules/globalPlayerData/actions'
import { selectControlsVisibility, selectControlsVisibilityCountdown } from 'store/modules/globalPlayerData/selectors'
import { Redux } from '../../../../../@types'
import { ClickableHandle, StyledHandle } from '../styled'

const TOOLTIP_VISIBILITY_TIMEOUT = 8000

interface Props {
  domain: number[]
  handle: SliderItem
  disabled?: boolean
  getHandleProps: GetHandleProps
  vertical?: boolean
  animationDuration?: number
  onKeyDown?: (event: SyntheticEvent<Element, KeyboardEvent>) => void
  showInitialHint?: boolean
  setControlsVisibilityCountdown: typeof setControlsVisibilityCountdown
  setControlsVisibility: typeof setControlsVisibility
  controlsLeftVisible: number
  controlsVisible: boolean
  onFocusChange?: (focused?: boolean) => void
}

const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
  vertical,
  animationDuration,
  onKeyDown,
  showInitialHint,
  setControlsVisibility,
  setControlsVisibilityCountdown,
  controlsLeftVisible,
  controlsVisible,
  onFocusChange,
}: Props) => {
  const { t } = useTranslation()
  const [handleRef, setHandleRef] = useState()
  const [tooltipLeftVisible, setTooltipLeftVisible] = useState(0)
  const handleRefCb = useCallback((node: Element) => {
    !handleRef && setHandleRef(node)
  }, [])

  useEffect(() => {
    if (showInitialHint && handleRef) {
      Tooltip.show(handleRef)
      setTooltipLeftVisible(TOOLTIP_VISIBILITY_TIMEOUT)

      setTimeout(() => {
        Tooltip.hide(handleRef)
        setTooltipLeftVisible(0)
      }, TOOLTIP_VISIBILITY_TIMEOUT)
    }
  }, [handleRef, showInitialHint])

  useEffect(() => {
    if (tooltipLeftVisible && controlsLeftVisible < tooltipLeftVisible) {
      setControlsVisibilityCountdown(tooltipLeftVisible)
    }

    if (!controlsVisible && tooltipLeftVisible) {
      setControlsVisibility(true)
    }
  }, [controlsLeftVisible, tooltipLeftVisible, controlsVisible])

  return (
    <>
      <ClickableHandle
        data-testid="vp-slider-handle-clickable"
        className={`vp-slider-handle-clickable ${
          vertical ? 'vp-slider-handle-clickable-vertical' : 'vp-slider-handle-clickable-horizontal'
        }`}
        percent={percent}
        vertical={vertical}
        {...getHandleProps(id)}
        animationDuration={animationDuration}
      />
      <StyledHandle
        ref={handleRefCb}
        data-tip={t('Slide to seek in the video')}
        className={`vp-slider-handle ${vertical ? 'vp-slider-handle-vertical' : 'vp-slider-handle-horizontal'}`}
        data-testid="vp-slider-handle-styled"
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        percent={percent}
        vertical={vertical}
        animationDuration={animationDuration}
        onKeyDown={onKeyDown}
        onFocus={() => onFocusChange && onFocusChange(true)}
        onBlur={() => onFocusChange && onFocusChange(false)}
      />
    </>
  )
}

const mapStateToProps = (state: Redux) => ({
  controlsLeftVisible: selectControlsVisibilityCountdown(state),
  controlsVisible: selectControlsVisibility(state),
})

export default connect(
  mapStateToProps,
  { setControlsVisibilityCountdown, setControlsVisibility },
)(Handle)
