import PlayArrow from 'components/icons/play'
import Replay from 'components/icons/replay'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { VideoType } from 'store/modules/currentVideo/@types'
import { selectCurrentVideo } from 'store/modules/currentVideo/selectors'
import { _get } from 'utils/get'
import { Redux } from '../../@types'
import { Icon, IconWrapper, TextWrapper, Wrapper } from './styled'

interface Props {
  videoType: VideoType
  alreadyCharged: boolean
  onClick: () => void
  showText?: boolean
  isVideoOver?: boolean
}

const PlayCTA = ({ videoType, alreadyCharged, onClick, showText, isVideoOver }: Props) => {
  const { t } = useTranslation()

  const handleClick = e => {
    e.stopPropagation()
    onClick()
  }

  const resolveText = () => {
    switch (videoType) {
      case VideoType.LIVE:
        return t('Play video')
      case VideoType.PAID:
        return alreadyCharged ? t('Play video') : t('Play video for credit')
      case VideoType.WITH_PREVIEW:
        return alreadyCharged ? t('Play video') : t('Play preview')
      default:
        return null
    }
  }

  const renderIcon = () => {
    if (isVideoOver) {
      return <Replay size={40} />
    }
    return <PlayArrow size={60} />
  }

  return (
    <Wrapper className="vp-play-cta" data-testid="vp-play-cta" onClick={handleClick}>
      <IconWrapper className="vp-play-cta-icon" data-testid="vp-play-cta-icon" onClick={handleClick}>
        <Icon>{renderIcon()}</Icon>
      </IconWrapper>
      {showText && (
        <TextWrapper className="vp-play-cta-text" data-testid="vp-play-cta-text">
          {resolveText()}
        </TextWrapper>
      )}
    </Wrapper>
  )
}

export default connect((state: Redux) => ({
  videoType: _get(selectCurrentVideo(state), 'type'),
  alreadyCharged: _get(selectCurrentVideo(state), 'alreadyCharged'),
}))(PlayCTA)
