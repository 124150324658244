import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import qs from 'qs'

interface RequestOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  headers?: Record<string, string>
  body?: Record<string, string>
}

const request = (url: string, { body, headers, ...options }: RequestOptions, params?: Record<string, string>) => {
  const containsQuery = url.includes('?')
  const rawUrl = containsQuery ? url.split('?')[0] : url
  const finalParams = containsQuery ? { ...qs.parse(url.split('?')[1]), ...params } : params

  return Axios({
    ...options,
    url: rawUrl,
    data: body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    params: finalParams,
    paramsSerializer: (params: Record<string, string>) => qs.stringify(params),
  })
    .then((response: AxiosResponse) => response.data)
    .catch((error: AxiosError) => {
      throw new FetchError(error)
    })
}

class FetchError extends Error {
  config: AxiosRequestConfig
  code?: string
  request?: any
  response?: AxiosResponse

  constructor({ response }: AxiosError) {
    super(response && response.statusText)

    this.config = response && response.config
    this.code = String(response && response.status)
    this.request = response && response.request
    this.response = response && response

    Object.setPrototypeOf(this, FetchError.prototype)
  }
}

export default request
