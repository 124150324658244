import styled from 'styled-components'

export const FloatingUnMuteButton = styled.button`
  margin: 0;
  border: 0;
  padding: 0;
  min-width: 0;
  z-index: 10;
  position: absolute;
  bottom: 40px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.floatingControlsBg};
  transition: 0.25s background linear;
  cursor: pointer;
  box-shadow: 0px 0px 30px 10px rgba(255, 255, 255, 1);
  color: #fff;
  fill: currentColor;

  &:hover {
    background: ${({ theme }) => theme.colors.floatingControlsHoverBg};
  }
`
