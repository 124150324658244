import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  z-index: 450;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:hover > * {
    background: ${({ theme }) => theme.colors.floatingControlsHoverBg};
  }
`

export const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  background: ${({ theme }) => theme.colors.floatingControlsBg};
  color: ${({ theme }) => theme.colors.playCTAFill};
  border-radius: 50%;
  fill: currentColor;
  transition: 0.25s all linear;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
`

export const Icon = styled.div`
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const TextWrapper = styled.div`
  margin: 10px auto 0 auto;
  padding: 5px 10px;
  background: ${({ theme }) => theme.colors.floatingControlsBg};
  color: ${({ theme }) => theme.colors.playCTAFill};
  border-radius: 5px;
  transition: 0.25s all linear;
  font-size: 13px;
`
