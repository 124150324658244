import { SVG } from 'components/icons/styled'
import React from 'react'

interface Props {
  size?: number
}

const VolumeOff = ({ size = 16 }: Props) => {
  return (
    <SVG data-testid="mute-icon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48">
      {/* tslint:disable-next-line */}
      <path d="M33 24c0-3.53-2.04-6.58-5-8.05v4.42l4.91 4.91c.06-.42.09-.85.09-1.28zm5 0c0 1.88-.41 3.65-1.08 5.28l3.03 3.03C41.25 29.82 42 27 42 24c0-8.56-5.99-15.72-14-17.54v4.13c5.78 1.72 10 7.07 10 13.41zM8.55 6L6 8.55 15.45 18H6v12h8l10 10V26.55l8.51 8.51c-1.34 1.03-2.85 1.86-4.51 2.36v4.13c2.75-.63 5.26-1.89 7.37-3.62L39.45 42 42 39.45l-18-18L8.55 6zM24 8l-4.18 4.18L24 16.36V8z" />
    </SVG>
  )
}

export default VolumeOff
