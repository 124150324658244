import { useState } from 'react'
import { useEventListener } from 'utils/hooks'

const CLICKS_TIMEOUT = 15000
const MAX_ALLOWED_CLICKS = 3

// @NOTE whole purpose of this component is a fallback, or "safety net". In some browsers (Smart TVs) user
// can get stuck in fullscreen mode > multiple clicks on same element turns that off
export const useSafetySwitch = (isFullScreen: boolean, handleSetFullScreen: (forceClose?: boolean) => void) => {
  const [clicksCount, setClicksCount] = useState({})

  const handler = e => {
    if (isFullScreen) {
      setClicksCount(prev => ({
        ...prev,
        [e.target.tagName]: (prev[e.target.tagName] || 0) + 1,
      }))
      setTimeout(() => {
        setClicksCount({})
      }, CLICKS_TIMEOUT)

      if (Object.values(clicksCount).some(count => count > MAX_ALLOWED_CLICKS)) {
        handleSetFullScreen(true)
      }
    }
  }

  useEventListener('click', handler)
  useEventListener('dblclick', handler)
}
