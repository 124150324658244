import styled, { DefaultTheme, ThemeProps } from 'styled-components'

interface WrapperProps extends ThemeProps<DefaultTheme> {
  visible?: boolean
}

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.controlsPanel};
  color: #fff;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: ${({ visible }: WrapperProps) => (visible ? 0 : '-30px')};
  fill: currentColor;
  z-index: 100;
  transition: 0.5s bottom ease;
  display: table;
`

export const Table = styled.div`
  display: table;
  height: 100%;
`

export const Row = styled.div`
  display: table-row;
  height: 100%;
`

export const Cell = styled.div`
  display: table-cell;
  height: 100%;
  vertical-align: top;
`

export const FullWidthCell = styled(Cell)`
  width: 100%;
`

export const ControlWrapper = styled.button`
  margin: 0;
  border: 0;
  padding: 0;
  color: #fff;
  min-width: 0;
  height: 100%;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.controlBg};
  transition: 0.2s all linear;

  &:hover {
    background: ${({ theme }) => theme.colors.controlHoverBg};
  }
`
