import SettingsIcon from 'components/icons/settings'
import { AllowedKeys } from 'components/KeyboardEventHandler'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { selectControlsVisibility } from 'store/modules/globalPlayerData/selectors'
import { _get } from 'utils/get'
import { Redux, VideoSource } from '../../../../@types'
import { Dropdown, Option, QualityButton, Wrapper } from './styled'

interface ComponentProps {
  selectedQuality: string
  selectQuality: (quality: string) => void
  sources: VideoSource[]
}

interface StateProps {
  controlsVisible: boolean
}

interface Props extends ComponentProps, StateProps {}

const VideoQualitySwitch = ({ selectedQuality, selectQuality, sources, controlsVisible }: Props) => {
  const { t } = useTranslation()
  const [isFocusWithin, setFocusWithin] = useState(false)

  const handleKeyDown = (e, position) => {
    if ([e.nativeEvent.code, e.nativeEvent.key].includes(AllowedKeys.ARROW_DOWN) && refs[position]) {
      e.nativeEvent.stopImmediatePropagation()
      e.preventDefault()

      if (refs[position + 1]) {
        refs[position + 1].focus()
      } else {
        mainButton && mainButton.focus()
      }
    } else if ([e.nativeEvent.code, e.nativeEvent.key].includes(AllowedKeys.ARROW_UP) && refs[position]) {
      e.nativeEvent.stopImmediatePropagation()
      e.preventDefault()

      if (refs[position - 1]) {
        refs[position - 1].focus()
      } else {
        mainButton && mainButton.focus()
      }
    }
  }

  const handleMainButtonKeyDown = e => {
    if ([e.nativeEvent.code, e.nativeEvent.key].includes(AllowedKeys.ARROW_UP) && refs[sources.length - 1]) {
      e.nativeEvent.stopImmediatePropagation()
      e.preventDefault()
      refs[sources.length - 1].focus()
    }
  }

  const handleSelectQuality = (source: VideoSource, index: number, e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.target && e.target instanceof HTMLButtonElement) {
      e.target.blur && e.target.blur()
    }
    selectQuality(_get(source, 'quality') || `${index + 1}`)
  }

  const [refs, setRefs] = useState({})
  const buttonsRefs = useCallback(
    (node: HTMLButtonElement, index: number) => {
      if (node && !refs[index]) {
        setRefs({ ...refs, [index]: node })
      }
    },
    [refs],
  )

  const [mainButton, setMainButtonRef] = useState(null)
  const mainButtonRef = useCallback((node: HTMLButtonElement) => {
    if (node && !mainButton) {
      setMainButtonRef(node)
    }
  }, [])

  return (
    <Wrapper
      controlsVisible={controlsVisible}
      isFocusWithin={isFocusWithin}
      data-testid="vp-quality-switch-container"
      className="vp-quality-switch-container"
    >
      <QualityButton
        data-testid="vp-quality-switch-btn"
        className="vp-quality-switch-btn"
        data-tip={t('Change quality')}
        ref={mainButtonRef}
        onKeyDown={handleMainButtonKeyDown}
        onFocus={() => setFocusWithin(true)}
        onBlur={() => setFocusWithin(false)}
      >
        <SettingsIcon />
      </QualityButton>
      <Dropdown data-tip={t('Change quality')} className="vp-quality-switch-dropdown">
        {(sources || []).map((source, index) => (
          <Option
            key={_get(source, 'quality') || `${index + 1}`}
            selected={(_get(source, 'quality') || `${index + 1}`) === selectedQuality}
            className="vp-quality-switch-option"
            data-testid="vp-quality-switch-option"
            onClick={e => handleSelectQuality(source, index, e)}
            ref={node => buttonsRefs(node, index)}
            onKeyDown={e => handleKeyDown(e, index)}
            onFocus={() => setFocusWithin(true)}
            onBlur={() => setFocusWithin(false)}
          >
            {_get(source, 'quality') || index + 1}
          </Option>
        ))}
      </Dropdown>
    </Wrapper>
  )
}

const mapStateToProps = (state: Redux) => ({
  controlsVisible: selectControlsVisibility(state),
})

export default connect(mapStateToProps)(VideoQualitySwitch)
