import styled from 'styled-components'

interface WrapperProps {
  controlsVisible?: boolean
  isFullscreen?: boolean
}

export const PlayerWrapper = styled.div`
  position: relative;
  background: #000;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: ${({ controlsVisible, isFullscreen }: WrapperProps) => (controlsVisible || !isFullscreen ? 'default' : 'none')};

  & .vp-player-wrapper {
    display: flex;
  }

  .vp-tooltip {
    padding: 8px 10px;
    border: 1px solid #fff;
    background-color: ${({ theme }) => theme.colors.tooltipBg};
    color: #fff;
    font-size: 11px;

    &.type-dark.place-top:after {
      border-top-color: #fff;
    }
  }

  video {
    z-index: 1;
  }
`
