import { AllowedKeys } from 'components/KeyboardEventHandler'
import KeyboardEventHandler from 'components/KeyboardEventHandler'
import { DEFAULT_VOLUME } from 'components/Player'
import React from 'react'
import { connect } from 'react-redux'
import { updatePlaybackData } from 'store/modules/playbackData/actions'
import { selectPlaybackData } from 'store/modules/playbackData/selectors'
import { _get } from 'utils/get'
import { Redux } from '../../@types'

export const VOLUME_SETTING_STEP_PERCENT = 0.1
export const SEEK_STEP_SECONDS = 10

interface ComponentProps {
  isPlaying: boolean
  setIsPlaying: (playing: boolean) => void
  duration?: number
  fileId?: string
  handleSeekTo?: (position: number) => void
  volume?: number
  onSetVolume?: (volume: number) => void
}

interface StateProps {
  volume?: number
  seekPosition: number
}

interface DispatchProps {
  updatePlaybackData: typeof updatePlaybackData
}

interface Props extends ComponentProps, StateProps, DispatchProps {}

const KeyboardControls = ({
  isPlaying,
  setIsPlaying,
  duration,
  seekPosition,
  volume,
  fileId,
  handleSeekTo,
  updatePlaybackData,
  onSetVolume,
}: Props) => {
  const handleKeyboardSeek = (seconds: number) => {
    const currentTime = duration * seekPosition
    const modified = currentTime + seconds

    if (modified > duration) {
      handleSeekTo && handleSeekTo(1)
    } else if (modified < 0) {
      handleSeekTo && handleSeekTo(0)
    } else {
      handleSeekTo && handleSeekTo(modified / duration)
    }
  }

  const handleVolumeFromKeyboard = (amount: number) => {
    const newVolume = volume + amount

    if (newVolume > 1) {
      onSetVolume ? onSetVolume(1) : updatePlaybackData({ fileId, volume: 1 })
    } else if (newVolume < 0) {
      onSetVolume ? onSetVolume(0) : updatePlaybackData({ fileId, volume: 0 })
    } else {
      onSetVolume ? onSetVolume(newVolume) : updatePlaybackData({ fileId, volume: newVolume })
    }
  }

  return (
    <>
      <KeyboardEventHandler
        key="classic-keyboard"
        onlyOnBodyElement={true}
        handlers={[
          {
            keyCode: AllowedKeys.SPACE,
            callback: () => setIsPlaying(!isPlaying),
          },
          {
            keyCode: AllowedKeys.SPACE_CHAR,
            callback: () => setIsPlaying(!isPlaying),
          },
          {
            keyCode: AllowedKeys.ARROW_UP,
            callback: () => handleVolumeFromKeyboard(VOLUME_SETTING_STEP_PERCENT),
          },
          {
            keyCode: AllowedKeys.ARROW_DOWN,
            callback: () => handleVolumeFromKeyboard(-VOLUME_SETTING_STEP_PERCENT),
          },
          ...(handleSeekTo && duration && fileId
            ? [
                {
                  keyCode: AllowedKeys.ARROW_RIGHT,
                  callback: () => handleKeyboardSeek(SEEK_STEP_SECONDS),
                },
                {
                  keyCode: AllowedKeys.ARROW_LEFT,
                  callback: () => handleKeyboardSeek(-SEEK_STEP_SECONDS),
                },
              ]
            : []),
        ]}
      />
      <KeyboardEventHandler
        key="smart-tvs-specific"
        onlyOnBodyElement={false}
        handlers={[
          {
            keyCode: AllowedKeys.MEDIA_PLAY,
            callback: () => setIsPlaying(true),
          },
          {
            keyCode: AllowedKeys.MEDIA_PAUSE,
            callback: () => setIsPlaying(false),
          },
          {
            keyCode: AllowedKeys.PAUSE,
            callback: () => setIsPlaying(false),
          },
          ...(handleSeekTo && duration && fileId
            ? [
                {
                  keyCode: AllowedKeys.MEDIA_FAST_FORWARD,
                  callback: () => handleKeyboardSeek(SEEK_STEP_SECONDS),
                },
                {
                  keyCode: AllowedKeys.MEDIA_FAST_BACKWARD,
                  callback: () => handleKeyboardSeek(-SEEK_STEP_SECONDS),
                },
              ]
            : []),
        ]}
      />
    </>
  )
}

const mapStateToProps = (state: Redux, props: ComponentProps) => {
  const playbackData = selectPlaybackData(props.fileId)(state)
  const volume = props.volume || props.volume === 0 ? props.volume : _get(playbackData, 'volume')

  return {
    volume: volume || volume === 0 ? volume : DEFAULT_VOLUME,
    seekPosition: _get(playbackData, 'seekPosition') || 0,
  }
}
export default connect(
  mapStateToProps,
  { updatePlaybackData },
)(KeyboardControls)
