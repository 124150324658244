import { ControlWrapper } from 'components/Controls/styled'
import styled, { DefaultTheme, ThemeProps } from 'styled-components'

interface WrapperProps extends ThemeProps<DefaultTheme> {
  isFocusWithin?: boolean
  controlsVisible: boolean
}

interface OptionProps extends ThemeProps<DefaultTheme> {
  selected?: boolean
}

export const Wrapper = styled.div`
  width: 25px;
  height: 100%;
  display: inline-block;

  & > div {
    opacity: ${({ isFocusWithin, controlsVisible }: WrapperProps) => (isFocusWithin && controlsVisible ? 1 : 0)};
    visibility: ${({ isFocusWithin, controlsVisible }: WrapperProps) => (isFocusWithin && controlsVisible ? 'visible' : 'hidden')};
  }

  &:hover > div,
  & > button:focus + div {
    opacity: ${({ controlsVisible }: WrapperProps) => (controlsVisible ? 1 : 0)};
    visibility: ${({ controlsVisible }: WrapperProps) => (controlsVisible ? 'visible' : 'hidden')};
  }

  & > div:focus-within {
    opacity: ${({ controlsVisible }: WrapperProps) => (controlsVisible ? 1 : 0)};
    visibility: ${({ controlsVisible }: WrapperProps) => (controlsVisible ? 'visible' : 'hidden')};
  }

  &:focus-within button {
    visibility: ${({ controlsVisible }: WrapperProps) => (controlsVisible ? 'visible' : 'hidden')};
    opacity: ${({ controlsVisible }: WrapperProps) => (controlsVisible ? 1 : 0)};
  }

  @media screen and (min-width: ${({ theme }: WrapperProps) => theme.layout.sizes.md}px) {
    width: 40px;
  }
`

export const SubtitlesButton = styled(ControlWrapper)`
  width: 25px;

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    width: 40px;
  }
`

export const Dropdown = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.controlsPanel};
  width: 60px;
  transition: 0.25s all linear;
  bottom: 30px;
  transform: translate(-15px, 0px);

  @media screen and (min-width: ${({ theme }) => theme.layout.sizes.md}px) {
    width: 70px;
    transform: translate(-15px, 0px);
  }
`

export const Clickable = styled.button`
  margin: 0;
  border: 0;
  min-width: 0;
  display: block;
  width: 100%;
  padding: 10px 0;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.controlBg};
  color: ${({ theme }) => theme.colors.unselectedText};
  transition: 0.25s linear all;
  text-align: center;
  font-size: 11px;

  &:hover {
    background: ${({ theme }) => theme.colors.controlHoverBg};
  }
`

export const Option = styled(Clickable)`
  background: ${({ theme, selected }: OptionProps) => (selected ? theme.colors.controlHoverBg : theme.colors.controlBg)};
  color: ${({ theme, selected }: OptionProps) => (selected ? '#fff' : theme.colors.unselectedText)};

  &:hover {
    background: ${({ theme }) => theme.colors.controlHoverBg};
  }
`
